import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ChatSupportBtn from "./ChatSupportBtn";
import moment from "moment";
import { logout } from "./Utility";
import customFetch from "./apicall/api";

class Disbursement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disbdata: [],
      disbdata_bkp: [],
      isSpin: false,
      isView: false,
      loan_documentation_id: "",
      loan_id: "",
      disbdate: "",
      partneref: "",
      loan_detail: [],
      isLoandetail: false,
      isNetoff: false,
      p_bal: "",
      bounceCharges: "",
      ovdChanges: "",
      int_due: "",
      netoff_ids: "",
    };
    this.handlesubmit = this.handlesubmit.bind(this);
    this.saveCMSData = this.saveCMSData.bind(this);
    this.modalRef = React.createRef();
    this.loadDisburseCase = this.loadDisburseCase.bind(this);
  }
  componentDidMount() {
    this.getdisbursecase();
  }

  loadDisburseCase() {
    this.state.isErrOpen = false;
    this.getdisbursecase();
  }

  getdisbursecase() {
    const { api_token, household_id } =
      (this.props.location && this.props.location.state) || {};
    this.setState({ isSpin: true });
    // AT-2143 | AT-2179 | Ankur Makavana | 12-07-2023 | conver post method to get method

    customFetch(process.env.REACT_APP_URL + "/get/disbursement/cases", {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        // console.log(json);
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          disbdata: json,
          disbdata_bkp: json,
          isSpin: false,
        });
      })
      .catch((error) => console.log("error", error));
  }
  showpopup(loan_id, loan_documentation_id) {
    this.setState({
      loan_id: loan_id,
      loan_documentation_id: loan_documentation_id,
      isView: true,
      disbdate: "",
      partneref: "",
      isErrOpen: false,
      api_error_message: "",
      sendcase: "",
      isSend: false,
    });
  }

  netOffPopup(loan_id, ref_doc_id, loan_documentation_id) {
    this.setState({
      isNetoff: true,
      loan_id: loan_id,
      loan_documentation_id: loan_documentation_id,
      isSpin: true,
      // AT-2314 | Vaibhav Sawant | 21-08-2023 | Previous values will not be loaded once the loan is marked net-off
      ovdChanges: "",
      bounceCharges:"",
      p_bal:"",
      int_due:""
    });
    this.forceUpdate();
    const { api_token, household_id } =
      (this.props.location && this.props.location.state) || {};

    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      ref_doc_id: ref_doc_id.replaceAll("|", ","),
    };
    const queryParams = new URLSearchParams(params).toString();
    customFetch(process.env.REACT_APP_URL + "/get/loanid/netoff?" + queryParams, {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log(this.state.p_bal);
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          netoff_ids: json.data,
          isSpin: false,
        });
      })
      .catch((error) => console.log("error", error));
  }

  saveCMSData(e) {
    e.preventDefault();
    this.setState({ isSpin: true });

    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    const formdata = new FormData();
    formdata.append("loan_documentation_id", this.state.loan_documentation_id);
    formdata.append("principal_balance", this.state.p_bal);
    formdata.append("interest_due", this.state.int_due);
    formdata.append("overdue_charge", this.state.ovdChanges);
    formdata.append("bounce_charge", this.state.bounceCharges);
    formdata.append("userid", uid);

    customFetch(process.env.REACT_APP_URL + "/save/netoff/data", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        // AT-2314 | Vaibhav Sawant | 21-08-2023 | Previous values will not be loaded once the loan is marked net-off
        this.setState({
          api_error_message: json.message,
          isErrOpen: true,
          isNetoff: false,
          isSpin: false,
          ovdChanges: "",
          bounceCharges:"",
          p_bal:"",
          int_due:""

        });

        this.getdisbursecase();
        this.forceUpdate();
      })
      .catch((error) => console.log("error", error));
  }

  handlesubmit(e) {
    e.preventDefault();
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    const formdata = new FormData();
    formdata.append("loan_documentation_id", this.state.loan_documentation_id);
    formdata.append("disbursement_date", this.state.disbdate);
    formdata.append("partner_reference_number_two", this.state.partneref);
    formdata.append("userid", uid);
    customFetch(process.env.REACT_APP_URL + "/send/disbursement/cases", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          sendcase: json.message,
          isSend: true,
          isSpin: false,
          isView: false,
          ovdChanges: "",
          bounceCharges:"",
          p_bal:"",
          int_due:""
        });

        this.getdisbursecase();
      })
      .catch((error) => console.log("error", error));
  }

  showloandata(loan_id) {
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    // AT-2143 | AT-2179 | Ankur Makavana | 12-07-2023 | conver post method to get method
    const params = {
      loan_id: loan_id,
    };
    const queryParams = new URLSearchParams(params).toString();
    customFetch(process.env.REACT_APP_URL + "/loan/household?" + queryParams, {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          loan_detail: json,
          isLoandetail: true,
          isSpin: false,
        });
      })
      .catch((error) => console.log("error", error));
  }

  filterdata() {
    this.state.sort_data = [];
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    if (this.state.searchid) {
      this.setState({ isSpin: true });

      // const formdata = new FormData();
      // formdata.append("loan_id", this.state.searchid);
      // AT-2143 | AT-2179 | Ankur Makavana | 12-07-2023 | conver post method to get method
      customFetch(
        process.env.REACT_APP_URL +
          "/get/disbursement/cases?loan_id=" +
          this.state.searchid,
        {
          method: "GET",
          headers: { "api-token": api_token },
          //body: formdata,
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          console.log(json);
          if (
            json.api_code === 4 ||
            json.api_code === 3 ||
            json.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            disbdata: json,
            disbdata_bkp: json,
            isSpin: false,
          });
        })
        .catch((error) => console.log("error", error));
    } else {
      this.setState({ isSpin: true });

      //const formdata = new FormData();
      // AT-2143 | AT-2179 | Ankur Makavana | 12-07-2023 | conver post method to get method
      customFetch(process.env.REACT_APP_URL + "/get/disbursement/cases", {
        method: "GET",
        headers: { "api-token": api_token },
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (
            json.api_code === 4 ||
            json.api_code === 3 ||
            json.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            disbdata: json,
            disbdata_bkp: json,
            isSpin: false,
          });
        })
        .catch((error) => console.log("error", error));
    }
  }
  render() {
    const {
      disbdata,
      isView,
      loan_id,
      partneref,
      isErrOpen,
      api_error_message,
      sendcase,
      isSend,
      loan_detail,
      isLoandetail,
      isNetoff,
      netoff_ids,
    } = this.state;
    const enabled = this.state.disbdate !== "" && this.state.partneref !== "";
    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.loadDisburseCase}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <div
          className={
            isLoandetail
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isLoandetail: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div>
                {loan_detail.map((loandata) => {
                  return (
                    <>
                      <div className="loanid-holder text-center">
                        <span>{loandata.name_as_per_account}</span>
                      </div>
                      <div className="loan-dataholder">
                        <div className="border-b2">
                          HH No : {loandata.household_number}
                        </div>
                        <div>Branch : {loandata.branch_name}</div>
                      </div>

                      <div className="loan-dataholder">
                        <div className="border-b2">
                          Bank Name : {loandata.bank_name}
                        </div>
                        <div className="border-b2">
                          Account No : {loandata.account_number}
                        </div>
                        <div className="border-b2">
                          Account Type : {loandata.account_type_name}
                        </div>
                        <div>IFSC : {loandata.ifsc}</div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isSend
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isSend: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{sendcase}</p>
            </div>
          </div>
        </div>
        <div
          className={
            isView
              ? "modal fade show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="0"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
          autoFocus={false}
          ref={this.modalRef}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isView: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <form onSubmit={this.handlesubmit}>
                <div className="loanid-holder text-center">
                  <span>Loan ID : {loan_id}</span>
                </div>
                <div className="selnach-holder">
                  <div className="row">
                    <div className="form-group col-6">
                      <label for="inputEmail4">Disbursement Date</label>
                      <input
                        type="date"
                        id="date_select"
                        className="form-control"
                        tabIndex="0"
                        autoFocus={true}
                        value={this.state.disbdate}
                        min={`${
                          new Date(
                            moment()
                              .subtract(1, "months")
                              .format("YYYY-MM-DD")
                          )
                            .toISOString()
                            .split("T")[0]
                        }`}
                        onChange={(e) =>
                          this.setState({ disbdate: e.target.value })
                        }
                        name="disbdate"
                        placeholder="Disbursement Date"
                      />
                    </div>
                    <div className="form-group col-6">
                      <label for="inputPassword4">Partner Ref#</label>
                      <input
                        className="form-control"
                        id="inputPassword4"
                        placeholder="Partner Reference Number"
                        value={this.state.partneref}
                        tabIndex="0"
                        onChange={(e) =>
                          this.setState({ partneref: e.target.value })
                        }
                        name="partneref"
                      />
                    </div>
                    <div className="col-12 text-center">
                      <input
                        type="submit"
                        tabIndex="0"
                        value="submit"
                        className="btn btn-outline-primary"
                        disabled={!enabled}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className={
            isNetoff
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isNetoff: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <form onSubmit={this.saveCMSData}>
                <div className="loanid-holder text-center">
                  <span>Loan ID : {loan_id}</span>
                  <br />
                  <span>Netoff / Forclosure Loan ID: {netoff_ids}</span>
                </div>
                <div className="selnach-holder">
                  <div className="row">
                    <div className="form-group col-3">
                      {/* <label for="inputPassword4">Principal Balance</label> */}
                      {/* AT-2314 | Vaibhav Sawant | 21-08-2023 | Previous values will not be loaded once the loan is marked net-off */}
                      <input
                        className="form-control"
                        id="principalBalance"
                        placeholder="Principal Balance"
                        value={this.state.p_bal}
                        onChange={(e) =>
                          this.setState({ p_bal: e.target.value })
                        }
                        name="principalBalance"
                        required
                      />
                    </div>
                    <div className="form-group col-3">
                      {/* <label for="inputPassword4">Interest Due</label> */}
                      <input
                        className="form-control"
                        id="intDue"
                        placeholder="Interest Due"
                        value={this.state.int_due}
                        onChange={(e) =>
                          this.setState({ int_due: e.target.value })
                        }
                        name="intDue"
                        required
                      />
                    </div>
                    <div className="form-group col-3">
                      {/* <label for="inputPassword4">Overdue Changes</label> */}
                      <input
                        className="form-control"
                        id="ovdChanges"
                        placeholder="Overdue Charges"
                        value={this.state.ovdChanges}
                        onChange={(e) =>
                          this.setState({ ovdChanges: e.target.value })
                        }
                        name="ovdChanges"
                        required
                      />
                    </div>
                    <div className="form-group col-3">
                      {/* <label for="inputPassword4">Bounce Changes</label> */}
                      <input
                        className="form-control"
                        id="bounceChanges"
                        placeholder="Bounce Charges"
                        value={this.state.bounceCharges}
                        onChange={(e) =>
                          this.setState({ bounceCharges: e.target.value })
                        }
                        name="bounceChanges"
                        required
                      />
                    </div>

                    <div className="col-12 text-center">
                      <input
                        type="submit"
                        value="submit"
                        className="btn btn-outline-primary"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <section className="main-sec">
          <div className="container fix-width">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="cso_icon_img"
                  alt=""
                />
              </div>
              <div className="head-title">Disburse</div>
              <div className="log-out-report">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>
            <div className="mt-3">
              <div className="search-area">
                <div className="row">
                  <div className="col-md-8">
                    <input
                      name="searchid"
                      className="form-control"
                      placeholder="search here..."
                      onChange={(e) => {
                        this.setState({ searchid: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.filterdata()}
                    >
                      search
                    </button>
                  </div>
                </div>
              </div>
              {disbdata.length > 0
                ? disbdata.map((item, index) => (
                    <div className="card-holder-disb mt-3" key={index}>
                      <div className="card-name-holder">
                        <div className="row">
                          <div className="col-10">
                            <div className="card-name">Loan ID :</div>
                            <div
                              className="card-hhid"
                              onClick={() => this.showloandata(item.loan_id)}
                            >
                              <span className="loan_data">{item.loan_id}</span>
                            </div>
                          </div>
                          <div className="col-2">
                            <span className="card-status">
                              <div>
                                {/* <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    this.showpopup(
                                      item.loan_id,
                                      item.loan_documentation_id
                                    )
                                  }
                                >
                                  add
                                </button> */}
                                {item.flag == 0 ? (
                                  <img
                                    src={require("../assets/images/plus.png")}
                                    className="cso_icon_img loan_data"
                                    alt=""
                                    tabIndex="0"
                                    onClick={() =>
                                      this.showpopup(
                                        item.loan_id,
                                        item.loan_documentation_id
                                      )
                                    }
                                    onKeyDown={(e) => {
                                      if (e.code === "Enter") {
                                        this.showpopup(
                                          item.loan_id,
                                          item.loan_documentation_id
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../assets/images/net-off.png")}
                                    className="cso_icon_img loan_data"
                                    alt=""
                                    onClick={() =>
                                      this.netOffPopup(
                                        item.loan_id,
                                        item.ref_loan_doc_id,
                                        item.loan_documentation_id
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </section>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}

export default withRouter(Disbursement);
