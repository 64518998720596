import React, { Component } from "react";
import { withRouter } from "react-router";
import { logout } from "./Utility";
import customFetch from "./apicall/api";

import ChatSupportBtn from "./ChatSupportBtn";

const txn_status_type = {
  Collected : "C",
  Deposited : "D"
  }
class Transaction_history extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      items_bkp: [],
      Category: "",
      Filter: "",
      Month: "",
      sort_data: [],
      FilterData: [],
      MonthData: [],
      CategoryData: [],
      Merge: [],
      temp_array: [],
      reversed: [],
      farray: [],
    };
  }
  componentDidMount() {
    document.title = "Transaction History";
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    console.log("Token", api_token);
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }

    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      in_userid: uid,
    };
    const queryParams = new URLSearchParams(params).toString();

    customFetch(
      process.env.REACT_APP_URL +
        "/get/transaction/history/finops?" +
        queryParams,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          items: data,
          items_bkp: data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  updateCategory(e) {
    this.setState({
      Category: e.target.value,
    });
    this.setState({ Category: e.target.value }, () => {
      console.log(this.state.Category, "dealersOverallTotal1");
      this.filterArray();
    });
  }
  updateFilter(e) {
    //this.state.sort_data = [];
    this.setState({ Filter: e.target.value }, () => {
      console.log(this.state.Filter, "dealersOverallTotal1");
      this.filterArray();
    });
  }

  updateMonth(e) {
    this.setState({
      Month: e.target.value,
    });

    this.setState({ Month: e.target.value }, () => {
      // console.log(this.state.Month, "dealersOverallTotal1");
      this.filterArray();
    });
  }
  filterArray() {
    if (this.state.Month != "") {
      this.state.sort_data = [];
      for (let i = 0; i < this.state.items.length; i++) {
        if (
          this.state.items[i].submitted_at
            .split(" ")[2]
            .toLowerCase()
            .indexOf(this.state.Month.toLowerCase()) == 0
        ) {
          this.state.sort_data.push(this.state.items[i]);
        }
      }

      if (this.state.Category != "") {
        if (
          this.state.Category.toLowerCase().indexOf("cutocso") == 0 ||
          this.state.Category.toLowerCase().indexOf("tocsototo") == 0
        ) {
          for (let i = 0; i < this.state.sort_data.length; i++) {
            if (this.state.sort_data[i].txn_type === txn_status_type.Collected) {
              this.state.farray.push(this.state.sort_data[i]);
            }
          }
        }

        if (
          this.state.Category.toLowerCase().indexOf("csototo") == 0 ||
          this.state.Category.toLowerCase().indexOf("totobank") == 0
        ) {
          for (let i = 0; i < this.state.sort_data.length; i++) {
            if (this.state.sort_data[i].txn_type === txn_status_type.Deposited) {
              this.state.farray.push(this.state.sort_data[i]);
            }
          }
        }

        this.state.sort_data = this.state.farray;
        this.state.farray = [];
      }

      if (this.state.Filter != "") {
        for (let i = 0; i < this.state.sort_data.length; i++) {
          if (this.state.sort_data[i].status == this.state.Filter) {
            this.state.farray.push(this.state.sort_data[i]);
          }
        }
        this.state.sort_data = this.state.farray;
        this.state.farray = [];
      }
    } else {
      if (this.state.Category != "") {
        this.state.sort_data = [];
        if (
          this.state.Category.toLowerCase().indexOf("cutocso") == 0 ||
          this.state.Category.toLowerCase().indexOf("tocsototo") == 0
        ) {
          for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].txn_type === txn_status_type.Collected) {
              this.state.sort_data.push(this.state.items[i]);
            }
          }
        }

        if (
          this.state.Category.toLowerCase().indexOf("csototo") == 0 ||
          this.state.Category.toLowerCase().indexOf("totobank") == 0
        ) {
          for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].txn_type === txn_status_type.Deposited) {
              this.state.sort_data.push(this.state.items[i]);
            }
          }
        }
      }

      if (this.state.Filter != "") {
        for (let i = 0; i < this.state.sort_data.length; i++) {
          if (this.state.sort_data[i].status == this.state.Filter) {
            this.state.farray.push(this.state.sort_data[i]);
          }
        }
        this.state.sort_data = this.state.farray;
        this.state.farray = [];
      }
    }

    if (this.state.Filter != "") {
      if (this.state.Month == "") {
        if (this.state.Category == "") {
          this.state.sort_data = [];
          for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].status == this.state.Filter) {
              this.state.sort_data.push(this.state.items[i]);
            }
          }
        }
      }

      if (this.state.Month != "") {
        this.state.sort_data = [];

        for (let i = 0; i < this.state.items.length; i++) {
          if (
            this.state.items[i].submitted_at
              .split(" ")[2]
              .toLowerCase()
              .indexOf(this.state.Month.toLowerCase()) == 0
          ) {
            if (this.state.items[i].status == this.state.Filter) {
              this.state.sort_data.push(this.state.items[i]);
            }
          }
        }
      }

      if (this.state.Category != "") {
        if (
          this.state.Category.toLowerCase().indexOf("cutocso") == 0 ||
          this.state.Category.toLowerCase().indexOf("tocsototo") == 0
        ) {
          for (let i = 0; i < this.state.sort_data.length; i++) {
            if (this.state.sort_data[i].txn_type === txn_status_type.Collected) {
              this.state.farray.push(this.state.sort_data[i]);
            }
          }
        }

        if (
          this.state.Category.toLowerCase().indexOf("csototo") == 0 ||
          this.state.Category.toLowerCase().indexOf("totobank") == 0
        ) {
          for (let i = 0; i < this.state.sort_data.length; i++) {
            if (this.state.sort_data[i].txn_type === txn_status_type.Deposited) {
              this.state.farray.push(this.state.sort_data[i]);
            }
          }
        }
        this.state.sort_data = this.state.farray;
        this.state.farray = [];
      }
    }

    this.state.items_bkp = this.state.sort_data.filter(
      (v, i, a) => a.indexOf(v) === i
    );
    // console.log(this.state.items_bkp);
    this.forceUpdate();

    // if (this.state.Month != "" || this.state.Category != "" || this.state.Filter != "") {

    //   if (this.state.Month != "") {
    //     this.state.sort_data = [];
    //     if (this.state.Category != "") {
    //       if (this.state.Category.toLowerCase().indexOf("cutocso") == 0) {
    //         for (let i = 0; i < this.state.items.length; i++) {
    //           if (this.state.items[i].txn_type === "C") {
    //             if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //               this.state.sort_data.push(this.state.items[i]);
    //             }
    //           }
    //         }
    //       }
    //       if (this.state.Category.toLowerCase().indexOf("csototo") == 0) {
    //         for (let i = 0; i < this.state.items.length; i++) {
    //           if (this.state.items[i].txn_type === "D") {
    //             if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //               this.state.sort_data.push(this.state.items[i]);
    //             }
    //           }
    //         }
    //       }
    //     } else {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //           this.state.sort_data.push(this.state.items[i]);
    //         }
    //       }
    //     }

    //     if (this.state.Filter != "") {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.items[i].status == this.state.Filter) {
    //           if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //             this.state.sort_data.push(this.state.items[i]);
    //           }
    //         }
    //       }
    //     } else {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //           this.state.sort_data.push(this.state.items[i]);
    //         }
    //       }
    //     }
    //   }

    //   if (this.state.Category != "") {
    //     this.state.sort_data = [];

    //     if (this.state.Month != "") {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //           if (this.state.Category.toLowerCase().indexOf("cutocso") == 0) {

    //             if (this.state.items[i].txn_type === "C") {
    //               this.state.sort_data.push(this.state.items[i]);
    //             }
    //           }
    //           if (this.state.Category.toLowerCase().indexOf("csototo") == 0) {
    //             if (this.state.items[i].txn_type === "D") {
    //               this.state.sort_data.push(this.state.items[i]);
    //             }
    //           }

    //         }
    //       }
    //     } else {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.Category.toLowerCase().indexOf("cutocso") == 0) {

    //           if (this.state.items[i].txn_type === "C") {
    //             this.state.sort_data.push(this.state.items[i]);
    //           }
    //         }
    //         if (this.state.Category.toLowerCase().indexOf("csototo") == 0) {
    //           if (this.state.items[i].txn_type === "D") {
    //             this.state.sort_data.push(this.state.items[i]);
    //           }
    //         }

    //       }

    //     }

    //     if (this.state.Filter != "") {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.items[i].status == this.state.Filter) {
    //           if (this.state.Category.toLowerCase().indexOf("cutocso") == 0) {
    //             if (this.state.items[i].txn_type === "C") {
    //               this.state.sort_data.push(this.state.items[i]);
    //             }
    //           }
    //           if (this.state.Category.toLowerCase().indexOf("csototo") == 0) {
    //             if (this.state.items[i].txn_type === "D") {
    //               this.state.sort_data.push(this.state.items[i]);
    //             }
    //           }
    //         }
    //       }
    //     }else {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.Category.toLowerCase().indexOf("cutocso") == 0) {

    //           if (this.state.items[i].txn_type === "C") {
    //             this.state.sort_data.push(this.state.items[i]);
    //           }
    //         }
    //         if (this.state.Category.toLowerCase().indexOf("csototo") == 0) {
    //           if (this.state.items[i].txn_type === "D") {
    //             this.state.sort_data.push(this.state.items[i]);
    //           }
    //         }

    //       }

    //     }
    //   }
    //   if (this.state.Filter != "") {
    //     this.state.sort_data = [];
    //     if (this.state.Month != "") {
    //       if (this.state.Category != "") {
    //         for (let i = 0; i < this.state.items.length; i++) {
    //           if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //             if (this.state.Category.toLowerCase().indexOf("cutocso") == 0) {
    //               if (this.state.items[i].txn_type === "C") {
    //                 if (this.state.items[i].status == this.state.Filter) {
    //                   this.state.sort_data.push(this.state.items[i]);
    //                 }
    //               }
    //             }
    //             if (this.state.Category.toLowerCase().indexOf("csototo") == 0) {
    //               if (this.state.items[i].txn_type === "D") {
    //                 if (this.state.items[i].status == this.state.Filter) {
    //                   this.state.sort_data.push(this.state.items[i]);
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       } else {
    //         for (let i = 0; i < this.state.items.length; i++) {
    //           if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //             if (this.state.items[i].status == this.state.Filter) {
    //               this.state.sort_data.push(this.state.items[i]);
    //             }
    //           }
    //         }
    //       }
    //     } else {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.items[i].status == this.state.Filter) {
    //           this.state.sort_data.push(this.state.items[i]);
    //         }
    //       }
    //     }
    //   }
    // } else {
    //   this.state.sort_data = this.state.items;
    // }

    // this.state.items = this.state.sort_data.filter((v, i, a) => a.indexOf(v) === i);
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }
  render() {
    const { items } = this.state;
    const { effective_designation_id } =
      (this.props.location && this.props.location.state) || {};
    let reversed = this.state.items_bkp.reverse();
    const e_designation_id = localStorage.getItem("e_designation_id");
    //const reversed = items.sort((a, b) => new Date(...a.submitted_at.split('/').reverse()) - new Date(...b.submitted_at.split('/').reverse()));
    return (
      <>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="">
              <div className="card-body">
                <div className="transaction-head-area">
                  <div
                    className="trans_back_icon_bg"
                    onClick={() => this.props.history.goBack()}
                  >
                    <img
                      src={require("../assets/images/back.png")}
                      className="back_icon_img"
                      alt=""
                    />
                  </div>
                  Transaction History
                  <div className="log-out-history">
                    <img
                      src={require("../assets/images/power-off.png")}
                      onClick={() => logout()}
                      alt=""
                    />
                  </div>
                </div>
                {/* <div className="transaction-header-area">
                  <div className="month-drop head-align">
                    <select
                      id="monthFilter"
                      className="select-data"
                      name="Month"
                      onChange={(e) => this.updateMonth(e)}
                      value={this.state.Month}
                    >
                      <option value="">Month</option>
                      <option value="Jan">January</option>
                      <option value="Feb">February</option>
                      <option value="Mar">March</option>
                      <option value="Apr">April</option>
                      <option value="May">May</option>
                      <option value="Jun">June</option>
                      <option value="Jul">July</option>
                      <option value="Aug">August</option>
                      <option value="Sep">September</option>
                      <option value="Oct">October</option>
                      <option value="Nov">November</option>
                      <option value="Dec">December</option>
                    </select>
                  </div>
                  <div className="category-drop head-align">
                    <select
                      id="categoryFilter"
                      className="select-data"
                      name="Category"
                      onChange={(e) => this.updateCategory(e)}
                      value={this.state.Category}
                    >
                      <option value="">Category</option>
                      {(effective_designation_id === 1 ||
                        e_designation_id === '1') && (
                          <>
                            <option value="cutocso">Customer to CSO</option>
                            <option value="csototo">CSO to TO</option>
                          </>
                        )}
                      {(effective_designation_id === 14 ||
                        e_designation_id === '14') && (
                          <>
                            <option value="tocsototo">CSO to TO</option>
                            <option value="totobank">TO to BANK</option>
                          </>
                        )}
                    </select>
                  </div>
                  <div className="filter-drop head-align" name="Filter" onChange={(e) => this.updateFilter(e)} value={this.state.Filter}>
                    <select className="select-data">
                      <option value="">Filter</option>
                      <option value="1">Saved</option>
                      <option value="2">Submitted</option>
                      <option value="3">Approved</option>
                      <option value="4">Rejected</option>
                    </select>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="transaction-card-holder">
              {reversed.length > 0
                ? reversed.map((item) => (
                    <>
                      {item.txn_type === txn_status_type.Deposited && (
                        <div className="card card-border mt-4">
                          <div className="card-body">
                            <div className="card-head-area">
                              <div className="card-image-holder">
                                <img
                                  src={require("../assets/images/payto.png")}
                                  alt=""
                                />
                              </div>
                              <div className="card-text">
                                <h2>
                                  Payment reconciled of{" "}
                                  <span className="capitalize">
                                    {item.username.toLowerCase()}
                                  </span>
                                  {/* to {item.from_to !== null
                                    ? item.from_to.toLowerCase()
                                    : ""}  */}
                                </h2>
                              </div>
                              <div className="cashid-div">
                                {item.cash_balance_id}
                              </div>
                            </div>
                            {/* <div className="card-amount-area">
                              <span>Amount : </span>
                              <span className="card-amt">
                                Rs {item.cso_cash_handover}
                              </span>
                            </div> */}
                            <div class="amt_details_block card-amount-area">
                              <div>
                                {/* <span class="bluetext">Submitted</span> */}
                                <div class="dem_block">
                                  Rs.{" "}
                                  {parseFloat(
                                    item.cso_cash_handover
                                  ).toLocaleString("en-IN")}
                                </div>
                              </div>
                              {/* <div>
                                <span class="greentext">Approved</span>
                                <div class="col_block">
                                  {item.status === 1 || item.status === 2
                                    ? "NA"
                                    : "Rs." +
                                      parseFloat(
                                        item.amt_approved
                                      ).toLocaleString("en-IN")}
                                </div>
                              </div> */}
                            </div>
                            {/* <div class="amt_bal_block card-amount-area">
                              <div>
                                <span class="redtext">Rejected</span>
                                <div class="bal_block">
                                  {item.status === 1 || item.status === 2
                                    ? "NA"
                                    : "Rs." +
                                      (
                                        parseFloat(item.cso_cash_handover) -
                                        parseFloat(item.amt_approved)
                                      ).toLocaleString("en-IN")}
                                </div>
                              </div>
                            </div> */}
                            <div className="card-foot-area">
                              <div className="date-time-area">
                                <div className="date-area">
                                  <span>Date : </span>
                                  <span>
                                    {item.submitted_at &&
                                      item.submitted_at.split(" ")[0] +
                                        " " +
                                        item.submitted_at.split(" ")[1] +
                                        " " +
                                        item.submitted_at.split(" ")[2] +
                                        " " +
                                        item.submitted_at.split(" ")[3]}
                                  </span>
                                </div>
                                <div className="time-area">
                                  <span>
                                    Time : {item.submitted_at.split(" ")[4]}
                                  </span>
                                </div>
                              </div>
                              <div className="status-area">
                                <span>
                                  {item.status === 1
                                    ? "Saved"
                                    : item.status === 2
                                    ? "Submitted"
                                    : item.status === 3
                                    ? "TO self Approved"
                                    : item.status === 4
                                    ? "Rejected"
                                    : item.status === 5
                                    ? "Verified"
                                    : ""}
                                </span>
                                {item.status === 1 && (
                                  <span className="foot-img-holder-save">
                                    <img
                                      src={require("../assets/images/Save.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 2 && (
                                  <span className="foot-img-holder-submit">
                                    <img
                                      src={require("../assets/images/Submit.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 5 && (
                                  <span className="foot-img-holder-approve">
                                    <img
                                      src={require("../assets/images/Approve.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 4 && (
                                  <span className="foot-img-holder-reject">
                                    <img
                                      src={require("../assets/images/Reject.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {item.txn_type === txn_status_type.Collected && (
                        <div className="card card-border mt-4">
                          <div className="card-body">
                            <div className="card-head-area">
                              <div className="card-image-holder">
                                <img
                                  src={require("../assets/images/receive-from.png")}
                                  alt=""
                                />
                              </div>
                              <div className="card-text">
                                <h2>Received from</h2>

                                <h3>
                                  {item.from_to && item.from_to.toLowerCase()}{" "}
                                </h3>
                              </div>
                            </div>
                            {/* <div className="card-amount-area">
                              <span>Amount : </span>
                              <span className="card-amt">
                                Rs {item.cso_cash_handover}
                              </span>
                            </div> */}
                            <div class="amt_details_block card-amount-area">
                              <div>
                                <span class="bluetext">Submitted</span>
                                <div class="dem_block">
                                  Rs.{" "}
                                  {parseFloat(
                                    item.cso_cash_handover
                                  ).toLocaleString("en-IN")}
                                </div>
                              </div>
                              <div>
                                <span class="greentext">Approved</span>
                                <div class="col_block">
                                  {item.status === 1 || item.status === 2
                                    ? "NA"
                                    : "Rs. " +
                                      parseFloat(
                                        item.amt_approved
                                      ).toLocaleString("en-IN")}
                                </div>
                              </div>
                            </div>
                            <div class="amt_bal_block card-amount-area">
                              <div>
                                <span class="redtext">Rejected</span>
                                <div class="bal_block">
                                  {item.status === 1 || item.status === 2
                                    ? "NA"
                                    : "Rs. " +
                                      (
                                        parseFloat(item.cso_cash_handover) -
                                        parseFloat(item.amt_approved)
                                      ).toLocaleString("en-IN")}
                                </div>
                              </div>
                            </div>
                            <div className="card-foot-area">
                              <div className="date-time-area">
                                <div className="date-area">
                                  <span>
                                    Date :{" "}
                                    {item.submitted_at.split(" ")[0] +
                                      " " +
                                      item.submitted_at.split(" ")[1] +
                                      " " +
                                      item.submitted_at.split(" ")[2] +
                                      " " +
                                      item.submitted_at.split(" ")[3]}
                                  </span>
                                </div>
                                <div className="time-area">
                                  <span>
                                    Time : {item.submitted_at.split(" ")[4]}
                                  </span>
                                </div>
                              </div>
                              <div className="status-area">
                                <span>
                                  {item.status === 1
                                    ? "Saved"
                                    : item.status === 2
                                    ? "Submitted"
                                    : item.status === 3
                                    ? "TO self Approved"
                                    : item.status === 4
                                    ? "Rejected"
                                    : item.status === 5
                                    ? "Verified"
                                    : ""}
                                </span>
                                {item.status === 1 && (
                                  <span className="foot-img-holder-save">
                                    <img
                                      src={require("../assets/images/Save.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 2 ||
                                  (item.status === 3 && (
                                    <span className="foot-img-holder-submit">
                                      <img
                                        src={require("../assets/images/Submit.png")}
                                        alt=""
                                      />
                                    </span>
                                  ))}
                                {item.status === 5 && (
                                  <span className="foot-img-holder-approve">
                                    <img
                                      src={require("../assets/images/Approve.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 4 && (
                                  <span className="foot-img-holder-approve">
                                    <img
                                      src={require("../assets/images/Reject.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))
                : ""}
            </div>
          </div>
        </section>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}

export default withRouter(Transaction_history);
