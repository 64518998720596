import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import customFetch from "./apicall/api";

const transaction_type = {
  Collection : "C"
};
export class To_slip_verification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numbers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      amounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      viewImg: "",
      viewImgType: "",
      isView: false,
      dep_slip: 0,
      dep_amt: 0,
      rkey: "",
      remark: "",
      editInput: [true, true, true, true, true, true, true, true, true, true],
      buttonInput: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      acceptInput: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      submitted_balance: 0,
      isDisable: true,
      isDisableReject: true,
      isOpen: false,
      isOpensuccess: false,
      isOpenreject: false,
      isSuccess: false,
      isReject: false,
      responseMessage: "",
      isapproveBtn: false,
      isRejectBtn: false,
      isSpin: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    document.title = "Deposit Money";
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    this.fetchDetails();
  }
  fetchDetails = () => {
    const uid = localStorage.getItem("in_userid");
    const { api_token, cash_balance_id } =
      (this.props.location && this.props.location.state) || {};

    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      in_userid: uid,
      cash_balance_id: cash_balance_id,
    };
    const queryParams = new URLSearchParams(params).toString();

    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": api_token,
      },
    };
    customFetch(
      process.env.REACT_APP_URL + "/get/denominations?" + queryParams,
      requestOptions
    )
      .then((response) => {
        console.log(response.headers);
        console.log(response.headers.entries());
        return response.json();
      })
      .then((data) => {
        console.log("Data", data);
        if (data.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({ remark: "" }, () => {
          console.log(this.state.remark, "remark");
        });

        let recon_key = data["recon_key"];
        let _submitted_balance = parseFloat(data["submitted_balance"]);
        let _cash_balance_id = data["cash_balance_id"];
        data = data["denomination"];

        let _numbers = this.state.numbers;
        let _amounts = this.state.amounts;

        for (let index = 0; index < data.length; index++) {
          let number = data[index]["qty_submitted"];
          let value = data[index]["currency_value"];
          //console.log(value + " " + number);
          if (value == "2000") {
            _numbers[0] = number;
            _amounts[0] = number * 2000;
          } else if (value == "500") {
            _numbers[1] = number;
            _amounts[1] = number * 500;
          } else if (value == "200") {
            _numbers[2] = number;
            _amounts[2] = number * 200;
          } else if (value == "100") {
            _numbers[3] = number;
            _amounts[3] = number * 100;
          } else if (value == "50") {
            _numbers[4] = number;
            _amounts[4] = number * 50;
          } else if (value == "20") {
            _numbers[5] = number;
            _amounts[5] = number * 20;
          } else if (value == "10") {
            _numbers[6] = number;
            _amounts[6] = number * 10;
          } else if (value == "5") {
            _numbers[7] = number;
            _amounts[7] = number * 5;
          } else if (value == "2") {
            _numbers[8] = number;
            _amounts[8] = number * 2;
          } else if (value == "1") {
            _numbers[9] = number;
            _amounts[9] = number * 1;
          }
        }
        let _total = 0;
        for (let i = 0; i < 10; i++) _total += _amounts[i];
        this.setState({
          numbers: _numbers,
          amounts: _amounts,
          total: _total,
          submitted_balance: _submitted_balance,
          cash_balance_id: _cash_balance_id,
          recon_key: recon_key,
        });
      }).catch((error) => console.log("error", error));;
  };
  viewimg(path) {
    console.log(path.split("/")[0]);
    console.log(path.split("/")[1]);
    console.log(path.split("/")[2]);
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    this.setState({
      isSpin: true,
    });
    // formdata.append("bucket_name", path.split("/")[0]);
    //   formdata.append("blob_name", path.split("/")[4]);
    formdata.append("blob_url", path);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
      },
      body: formdata,
    };
    customFetch(process.env.REACT_APP_URL + "/get/public/url", requestOptions)
      .then((res) => {
        if (res.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        }
        return res.json();
      })
      .then((img) => {
        //console.log(URL.createObjectURL(img))
        this.setState({
          viewImg: img.public_url,
          viewImgType: img.type,
          isOpen: true,
          isSpin: false,
        });
      }).catch((error) => console.log("error", error));;
  }
  handleChange(event) {
    if (event.target.name === "rkey") {
      if (event.target.value.length <= 50) {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.checked) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    } else {
      this.setState({
        [event.target.name]: 0,
      });
    }
  }

  handleReject = () => {
    const { api_token, cash_balance_id, path, to_id } =
      (this.props.location && this.props.location.state) || {};
    this.setState({
      isRejectBtn: true,
      isSpin: true,
    });
    let denominations = {};
    let _numbers = this.state.numbers;
    const uid = localStorage.getItem("in_userid");

    let pay_load = {
      in_userid: to_id,
      status: "04",
      approved_by: uid,
      txn_type: transaction_type.Collection, // C for Collection
    };
    pay_load["deposit_slip_verified"] = this.state.dep_slip;
    pay_load["deposit_amount_verified"] = this.state.dep_amt;
    pay_load["recon_key"] = this.state.rkey;
    pay_load["remark"] = this.state.rejreson;

    if (this.state.cash_balance_id != null)
      pay_load["cash_balance_id"] = this.state.cash_balance_id;

    let _body = JSON.stringify(pay_load);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-token": api_token,
      },
      body: _body,
    };
    console.log(_body);

    customFetch(process.env.REACT_APP_URL + "/save/denominations", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
        if (data.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        } else if (data.message.includes("Internal")) {
          this.setState({
            isReject: true,
            isOpenreject: false,
            responseMessage: data.message,
            isSpin: false,
          });
        } else {
          this.setState({
            isOpenreject: false,
            isReject: true,
            responseMessage: "Transaction Rejected!!!",
            isSpin: false,
          });
        }
      }).catch((error) => console.log("error", error));;
  };

  handleSave = (event) => {
    event.preventDefault();
    this.setState({
      isapproveBtn: true,
      isSpin: true,
    });
    const { api_token, to_id, amt_submitted } =
      (this.props.location && this.props.location.state) || {};
    let denominations = {};
    let _numbers = this.state.numbers;

    for (let i = _numbers.length; i >= 0; i--) {
      // if(_numbers[i] <= 0)
      //   continue;
      if (i == 0) denominations["2000"] = _numbers[i];
      else if (i == 1) denominations["500"] = _numbers[i];
      else if (i == 2) denominations["200"] = _numbers[i];
      else if (i == 3) denominations["100"] = _numbers[i];
      else if (i == 4) denominations["50"] = _numbers[i];
      else if (i == 5) denominations["20"] = _numbers[i];
      else if (i == 6) denominations["10"] = _numbers[i];
      else if (i == 7) denominations["5"] = _numbers[i];
      else if (i == 8) denominations["2"] = _numbers[i];
      else if (i == 9) denominations["1"] = _numbers[i];
    }

    const uid = localStorage.getItem("in_userid");

    let pay_load = {
      in_userid: to_id,
      status: "03",
      approved_by: uid,
      total_amt: parseFloat(amt_submitted),
      denomination_details: denominations,
      remark: "Approved From Fin-Ops",
      txn_type: transaction_type.Collection, // C for Collection
    };
    pay_load["deposit_slip_verified"] = this.state.dep_slip;
    pay_load["deposit_amount_verified"] = this.state.dep_amt;
    pay_load["recon_key"] = this.state.rkey;

    if (this.state.cash_balance_id != null)
      pay_load["cash_balance_id"] = this.state.cash_balance_id;

    let _body = JSON.stringify(pay_load);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-token": api_token,
      },
      body: _body,
    };
    console.log(_body);

    customFetch(process.env.REACT_APP_URL + "/save/denominations", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        } else if (data.message.includes("Internal")) {
          this.setState({
            isSuccess: true,
            isOpensuccess: false,
            responseMessage: data.message,
            isSpin: false,
          });
        } else {
          this.setState({
            isOpenreject: false,
            isReject: true,
            responseMessage: "Transaction Verified!!!",
            isSpin: false,
          });
        }
        // this.setState({ isOpensuccess: false, isSuccess: true });
      }).catch((error) => console.log("error", error));;
  };
  chkreject = () => {
    console.log("Reson", this.state.rejreson);
    if (this.state.rejreson.length <= 10) {
      this.setState({
        form_error: "remark must be more than 10 charecters",
      });
    } else {
      this.handleReject();
    }
  };
  render() {
    const {
      isOpen,
      viewImg,
      viewImgType,
      isView,
      isReject,
      isSuccess,
      isDisable,
      isDisableReject,
      isOpenreject,
      isOpensuccess,
    } = this.state;
    const { api_token, amt_submitted, path, slipstatus } =
      (this.props.location && this.props.location.state) || {};
    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            isOpen
              ? "modal fade bd-example-modal-lg show mymodal"
              : "modal fade bd-example-modal-lg"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isOpen: false, isView: true })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {(viewImg && viewImg.split(".").pop() === "pdf") ||
              viewImg.split(".").pop() === "PDF" ? (
                // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
                <iframe
                  className="pdfviewfile"
                  src={
                    viewImg + "#toolbar=1&navpanes=0&scrollbar=0&page=1&zoom=25"
                  }
                ></iframe>
              ) : (
                <img src={viewImg} />
              )}
            </div>
          </div>
        </div>

        <div
          className={
            isOpensuccess
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isOpensuccess: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Do You Really Want To Approve ?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => this.setState({ isOpensuccess: false })}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={this.state.isapproveBtn}
                  className="btn btn-primary"
                  onClick={this.handleSave}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            isOpenreject
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isOpenreject: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Do You Really Want To Reject ?</p>
                <div>
                  <textarea
                    className="form-control"
                    name="rejreson"
                    onChange={(e) =>
                      this.setState({ rejreson: e.target.value })
                    }
                  ></textarea>
                  <span className="maintaenance-text">
                    {this.state.form_error}
                  </span>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => this.setState({ isOpenreject: false })}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.chkreject}
                  disabled={this.state.isRejectBtn}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            isSuccess
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.setState({ isSuccess: false });
                    this.props.history.goBack();
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>{this.state.responseMessage}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({ isSuccess: false });
                    this.props.history.goBack();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            isReject
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.setState({ isReject: false });
                    this.props.history.goBack();
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>{this.state.responseMessage}</p>
                {/* <p></p> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({ isReject: false });
                    this.props.history.goBack();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="cash-acceptance-denom">
          <div className="container d-flex flex-column align-items-center">
            <div className="ca-dn-card d-flex flex-column align-items-center">
              <div className="collection_block_header">
                <div className="title-holder_bg">
                  <div
                    className="back_icon_bg"
                    onClick={() => this.props.history.goBack()}
                  >
                    <img
                      src={require("../assets/images/back.png")}
                      className="back_icon_img"
                    />
                  </div>
                  <h2>TO Slip Verification</h2>
                </div>
              </div>
              <div className="dn-container mt-3">
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-header-text col-md-6">
                    Amount Deposited:
                  </div>
                  <div className="dn-header-text col-md-3">
                    <div className=" dn-total dn-header-text">
                      Rs. {amt_submitted.toLocaleString()}
                    </div>
                  </div>
                  <div className="dn-row-box dn-header-text col-md-3">
                    <button
                      onClick={() => this.viewimg(path)}
                      data-toggle="modal"
                      data-target="#submit-modal"
                      className="btn btn-light rounded-lg"
                    >
                      <img
                        src={require("../assets/images/view_blue.png")}
                        alt=""
                      />{" "}
                      View
                    </button>
                  </div>
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-header-text">Denom</div>
                  <div className="dn-row-box dn-header-text">Number</div>
                  <div className="dn-row-box dn-header-text">Amount</div>
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">2000</div>
                  <input
                    name="2000"
                    disabled={this.state.editInput[0]}
                    type="text"
                    onChange={this.handleInputChange}
                    value={this.state.numbers[0]}
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[0].toLocaleString()}
                  </div>
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">500</div>
                  <input
                    name="500"
                    disabled={this.state.editInput[1]}
                    type="text"
                    onChange={this.handleInputChange}
                    value={this.state.numbers[1]}
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[1].toLocaleString()}
                  </div>
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">200</div>
                  <input
                    name="200"
                    disabled={this.state.editInput[2]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[2]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[2].toLocaleString()}
                  </div>
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">100</div>
                  <input
                    name="100"
                    disabled={this.state.editInput[3]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[3]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[3].toLocaleString()}
                  </div>
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">50</div>
                  <input
                    name="50"
                    disabled={this.state.editInput[4]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[4]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[4].toLocaleString()}
                  </div>
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">20</div>
                  <input
                    name="20"
                    disabled={this.state.editInput[5]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[5]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[5].toLocaleString()}
                  </div>
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">10</div>
                  <input
                    name="10"
                    disabled={this.state.editInput[6]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[6]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[6].toLocaleString()}
                  </div>
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">5</div>
                  <input
                    name="5"
                    disabled={this.state.editInput[7]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[7]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[7].toLocaleString()}
                  </div>
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">2</div>
                  <input
                    name="2"
                    disabled={this.state.editInput[8]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[8]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[8].toLocaleString()}
                  </div>
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">1</div>
                  <input
                    name="1"
                    disabled={this.state.editInput[9]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[9]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[9].toLocaleString()}
                  </div>
                </div>
                {isView && slipstatus == 2 ? (
                  <>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <div className="slip-verify col-md-12">
                        <div className="col-md-10 float-left pl-0">
                          <span className="verify-foot-text">
                            Denominations Match With Attached Slip?
                          </span>
                        </div>
                        <div className="col-md-2 float-left text-right">
                          <input
                            type="checkbox"
                            value="1"
                            name="dep_slip"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                      <div className="slip-verify col-md-12">
                        <div className="col-md-10 float-left pl-0">
                          <span className="verify-foot-text">
                            Deposit Amount Match with Bank Statement?
                          </span>
                        </div>
                        <div className="col-md-2 float-left text-right">
                          <input
                            type="checkbox"
                            value="1"
                            name="dep_amt"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <div className="slip-verify col-md-12">
                        <input
                          type="text"
                          name="rkey"
                          className="recon-inp email-box"
                          placeholder="Please enter bank statement UTR no"
                          onChange={this.handleChange}
                          value={this.state.rkey}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            {slipstatus == 2 ? (
              <div className="foot-button">
                <button
                  disabled={
                    !this.state.rkey ||
                    !this.state.dep_amt ||
                    !this.state.dep_slip
                  }
                  onClick={() => this.setState({ isOpensuccess: true })}
                  className="btn btn-primary m-3"
                >
                  Approve
                </button>
                <button
                  disabled={
                    this.state.rkey && this.state.dep_amt && this.state.dep_slip
                  }
                  onClick={() => this.setState({ isOpenreject: true })}
                  className="btn btn-danger  m-3"
                >
                  Reject
                </button>
              </div>
            ) : slipstatus == 3 ? (
              <div className=" justify-content-between align-items-center">
                <div className="mt-4">
                  This deposit slip is Approved.
                  <br />
                  Recon Key: {this.state.recon_key}
                </div>
              </div>
            ) : slipstatus == 4 ? (
              <div className=" justify-content-between align-items-center">
                <div className="mt-4">
                  This deposit slip is Rejected.
                  <br />
                  Recon Key: {this.state.recon_key}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(To_slip_verification);
