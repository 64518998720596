import React, { createContext, useContext, useState, useEffect } from "react";
import { Drawer, Button, Input } from "antd";
import { useLocation } from "react-router-dom";
import { CheckOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import customFetch from "../apicall/api";
import styles from "./Permission.module.css";

const permission_status_type ={
  Active: "A",
  Expired: "E"
}

const APPNAME = "finops";

export const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [isAdminMode, setIsAdminMode] = useState(false);
  const [longPressActive, setLongPressActive] = useState(false);
  const [currentComponentId, setCurrentComponentId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedFunctionality, setSelectedFunctionality] = useState({});
  const [elements, setElements] = useState({});
  const [roleData, setRoleData] = useState(null);
  const [functionalities, setFunctionalities] = useState([]);
  const [componentPath, setcomponentPath] = useState([]);

  const [permissions, setPermissions] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (
      roleData &&
      parseInt(roleData.effective_designation_id) ===
        parseInt(process.env.REACT_APP_PERMISSION_ADMIN_ED_ID)
    ) {
      setIsAdminMode(true);
    }
  }, [roleData]);

  useEffect(() => {
    // to get the role information for the the user, to identify if the user id admin
    if (location.pathname != "/" && !roleData) {
      getRole();
    }

    if (location.pathname != "/") {
      saveComponentId(location.pathname);
      setcomponentPath(location.pathname);
      if (!isAdminMode) {
        fetchPermissions();
      } else {
        return;
      }
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname == "/" || !isAdminMode) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;
    if (currentComponentId) {
      setFunctionalities([]);
      setSelectedFunctionality({});
      if (isAdminMode) setDrawerOpen(true);
      fetchElements(signal);
      fetchFunctionality(signal);
    }
    return () => {
      controller.abort();
    };
  }, [currentComponentId]);

  const showDrawer = () => {
    setDrawerOpen(true);
  };

  const onClose = () => {
    if (!Object.keys(selectedFunctionality).length) {
      return;
    }
    setDrawerOpen(false);
  };

  const getRole = async () => {
    try {
      const params = {
        userid: localStorage.getItem("in_userid"),
      };
      const queryParams = new URLSearchParams(params).toString();
      const response = await customFetch(
        process.env.REACT_APP_URL + "/getrole?" + queryParams,
        {
          method: "GET",
          headers: { "api-token": localStorage.getItem("api_token") },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setRoleData(data);
      } else {
        console.error("Failed to user role:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching the user role:", error);
    }
  };
  // api to save component id
  const saveComponentId = async (location_path) => {
    try {
      const formdata = new FormData();
      formdata.append("flag", "save_component");
      formdata.append("app_name", "FinOps");
      formdata.append(
        "component_name",
        `${APPNAME}_${location_path.replace("/", "")}`
      );

      const response = await customFetch(
        process.env.REACT_APP_URL + "/permission/data",
        {
          method: "POST",
          body: formdata,
          headers: {
            "api-token": localStorage.getItem("api_token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCurrentComponentId(data["objectid"]);
      } else {
        console.error("Failed to fetch permissions:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  // api to save element permissions
  const saveElement = async (element_names) => {
    try {
      const formdata = new FormData();

      formdata.append("flag", "save_elements");
      formdata.append("component_id", currentComponentId);
      formdata.append("element_arr", JSON.stringify(element_names));

      const response = await customFetch(
        process.env.REACT_APP_URL + "/permission/data",
        {
          method: "POST",
          body: formdata,
          headers: {
            "api-token": localStorage.getItem("api_token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        fetchElements();
        return data;
      } else {
        console.error("Failed to save element name :", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  // fetch elements
  const fetchElements = async (signal) => {
    try {
      const formdata = new FormData();
      formdata.append("component_id", currentComponentId);
      formdata.append("flag", "fetch_elements");
      const response = await customFetch(
        process.env.REACT_APP_URL + "/permission/data",
        {
          method: "POST",
          body: formdata,
          headers: {
            "api-token": localStorage.getItem("api_token"),
          },
          signal: signal,
        }
      );

      if (response.ok) {
        const data = await response.json();
        const _element_name_id_map = data["elements"].reduce((map, value) => {
          map[value["element_name"]] = value;
          return map;
        }, []);
        setElements(_element_name_id_map);
        return _element_name_id_map;
      } else {
        console.error("Failed to fetch elements :", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching permissions :", error);
    }
  };

  // api to save element permissions

  // returns the permissions for the provided component_id

  const getElementById = (element_id) => {
    return elements[element_id] || {};
  };

  const saveFunctionality = async (functionality_name, element_permission) => {
    try {
      const formdata = new FormData();

      formdata.append("flag", "save_functionality");
      formdata.append("status", permission_status_type.Active);
      formdata.append("component_id", currentComponentId);
      //formdata.append("function_name", functionality_name);
      if (element_permission) {
        formdata.append(
          "element_permissions",
          JSON.stringify(element_permission)
        );
      } else {
        formdata.append("element_permissions", JSON.stringify([]));
      }
      let _pathname;
      if (!functionality_name.includes(componentPath.replace("/", ""))) {
        // Append str to data
        _pathname = componentPath.replace("/", "") + "_" + functionality_name;
      } else {
        _pathname = functionality_name;
      }
      formdata.append("function_name", _pathname);
      const response = await customFetch(
        process.env.REACT_APP_URL + "/permission/data",
        {
          method: "POST",
          body: formdata,
          headers: {
            "api-token": localStorage.getItem("api_token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        alert(data.message);
        fetchFunctionality();
      } else {
        console.error("Failed to save functionality:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving functionality:", error);
    }
  };

  const fetchFunctionality = async (signal) => {
    try {
      const formdata = new FormData();
      formdata.append("component_id", currentComponentId);
      formdata.append("flag", "fetch_functionality");
      const response = await customFetch(
        process.env.REACT_APP_URL + "/permission/data",
        {
          method: "POST",
          body: formdata,
          headers: {
            "api-token": localStorage.getItem("api_token"),
          },
          signal: signal,
        }
      );

      if (response.ok) {
        const data = await response.json();
        setFunctionalities(data["functionality"]);
      } else {
        console.error("Failed to fetch functionality:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching functionality:", error);
    }
  };

  const fetchPermissions = async () => {
    try {
      const formdata = new FormData();
      formdata.append("flag", "fetch_user_functionality");
      formdata.append("branch_id", localStorage.getItem("branch_id"));
      formdata.append(
        "effective_designation",
        localStorage.getItem("e_designation_id")
      );
      formdata.append("userid", localStorage.getItem("in_userid"));
      const response = await customFetch(
        process.env.REACT_APP_URL + "/permission/data",
        {
          method: "POST",
          body: formdata,
          headers: {
            "api-token": localStorage.getItem("api_token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setPermissions(data["user_functionality_mapping"]);
      } else {
        console.error("Failed to fetch permissions:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const saveElementPermission = (elementPermission) => {
    const currentElementPermission = selectedFunctionality.element_permission;
    const index = currentElementPermission.findIndex(
      (element) => element.element_id === elementPermission.element_id
    );

    if (index !== -1) {
      currentElementPermission[index].permission = elementPermission.permission;
    } else {
      currentElementPermission.push(elementPermission);
    }
    saveFunctionality(
      selectedFunctionality.function_name,
      currentElementPermission
    );
  };

  const getPermissionById = (element_id) => {
    if (isAdminMode) {
      const currentElementPermission = selectedFunctionality.element_permission;
      if (currentElementPermission && currentElementPermission.length != 0) {
        const index = currentElementPermission.findIndex(
          (element) => element.element_name === element_id
        );
        if (index != -1) {
          return currentElementPermission[index].permission;
        }
      }
    } else {
      if (!permissions) return null;
      for (const entry of permissions) {
        const userFunctionalities = entry.functionalities || [];
        for (const functionality of userFunctionalities) {
          const elementPermissions = functionality.element_permission || [];
          for (const element of elementPermissions) {
            if (element.element_name === element_id) {
              return element.permission;
            }
          }
        }
      }
      return permission_status_type.Expired;
    }
  };

  return (
    <PermissionsContext.Provider
      value={{
        isAdminMode,
        getPermissionById,
        longPressActive,
        setLongPressActive,
        saveElement,
        fetchElements,
        getElementById,
        saveElementPermission,
      }}
    >
      <div className={styles.functionality_drawer_button}>
        {isAdminMode && <Button onClick={showDrawer}>Functionality</Button>}
      </div>
      <Drawer title="Functionality" onClose={onClose} open={drawerOpen}>
        <FunctionalitySelector
          selectedFunctionality={selectedFunctionality}
          functionalities={functionalities}
          selectFunctionality={(value) => {
            setSelectedFunctionality(value);
          }}
          saveFunctionality={saveFunctionality}
        />
      </Drawer>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  return useContext(PermissionsContext);
};

const { Search } = Input;

const FunctionalitySelector = ({
  functionalities,
  selectFunctionality,
  selectedFunctionality,
  saveFunctionality,
}) => {
  //dev mode states, remove during commit
  const [filteredFunctionalities, setFilteredFunctionalities] = useState([]);
  const [addLoading, setAddLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setFilteredFunctionalities(functionalities);
  }, [functionalities]);

  const searchFunctionality = (e) => {
    const functionlityName = e.target.value;
    setSearchQuery(functionlityName);

    if (functionlityName === "") {
      setFilteredFunctionalities(functionalities);
      return;
    }

    const _filteredFunctionalities = functionalities.filter((functionality) => {
      const name = functionality.function_name;
      if (name.toLowerCase().includes(functionlityName.toLowerCase()))
        return true;
    });

    setFilteredFunctionalities(_filteredFunctionalities);
  };

  const addFunctionalityHandler = async (value) => {
    setAddLoading(true);
    await saveFunctionality(value);
    setSearchQuery("");
    setFilteredFunctionalities(functionalities);
    setAddLoading(false);
  };
  return (
    <div>
      {filteredFunctionalities.length != 0 ? (
        <Search
          size="middle"
          onChange={searchFunctionality}
          placeholder="Functionality name"
        />
      ) : (
        <Search
          size="middle"
          onChange={searchFunctionality}
          placeholder="Functionality name"
          value={searchQuery}
          loading={addLoading}
          onSearch={addFunctionalityHandler}
          enterButton="Add"
        />
      )}
      {filteredFunctionalities.map((functionality) => {
        const functionalityName = functionality.function_name;
        const isSelected =
          functionalityName === selectedFunctionality.function_name;
        return (
          <div
            className={
              isSelected
                ? styles.functionality_item_selected
                : styles.functionality_item
            }
            key={functionalityName}
            onClick={() => {
              selectFunctionality(functionality);
            }}
          >
            {functionalityName}
            {isSelected && (
              <CheckCircleTwoTone twoToneColor="#52c41a" width={3} />
            )}
          </div>
        );
      })}
    </div>
  );
};
