import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Tabs, { Tab } from "react-best-tabs";
import "react-best-tabs/dist/index.css";
import "antd/dist/antd.css";
import { Switch } from "antd";
import ChatSupportBtn from "./ChatSupportBtn";
import { logout } from "./Utility";
import customFetch from "./apicall/api";

const settlement_type = {
  Bounce: "B",
  Fail: "F"
};
const timezone = {Time: "T"};
class NACH_Fail_Cases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isErrOpen: false,
      api_error_message: "",
      NACH_failpull: [],
      Mandate_fail: [],
      Pull_check: [],
      monthdata: "",
      to_date: "",
      from_date: "",
      to_date_m: "",
      from_date_m: "",
    };
  }
  componentDidMount() {
    this.fetchfailpull();
    //this.fetchfailmandate();
  }
  // componentDidUpdate() {
  //   const $ = window.$;
  //   $(document).ready(function () {
  //     setTimeout(() => {
  //       $("#findash").DataTable();
  //       $("#findash_sanc").DataTable();
  //     }, 2000);
  //   });
  // }
  fetchfailmandate() {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = { input_flag: "2" };
    if (this.state.to_date_m) {
      params.push({
        to_date: moment(this.state.to_date_m).format("YYYY-MM-DD"),
      });
    } else {
      params.push({ to_date: moment().format("YYYY-MM-DD") });
    }
    if (this.state.from_date_m) {
      params.push({
        from_date: moment(this.state.from_date_m).format("YYYY-MM-DD"),
      });
    } else {
      params.push({ from_date: moment("2022-10-15").format("YYYY-MM-DD") });
    }
    const queryParams = new URLSearchParams(params).toString();    
    customFetch(
      process.env.REACT_APP_URL + "/get/nach/failed/records?" + queryParams,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
          });
        }
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState(
          {
            Mandate_fail: json,
          }
          // function () {
          //   const $ = window.$;
          //   $("#findash_advance").DataTable();
          // }
        );
      })
      .catch((error) => console.log("error", error));
  }
  fetchfailpull() {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = { input_flag: "1" };
    if (this.state.to_date_m) {
      params["to_date"] = moment(this.state.to_date_m).format("YYYY-MM-DD");
    } else {
      params["to_date"] = moment().format("YYYY-MM-DD");
    }
    if (this.state.from_date_m) {
      params["from_date"] = moment(this.state.from_date_m).format("YYYY-MM-DD");
    } else {
      params["from_date"] = moment("2022-10-15").format("YYYY-MM-DD");
    }
    const queryParams = new URLSearchParams(params).toString();    
    customFetch(
      process.env.REACT_APP_URL + "/get/nach/failed/records?" + queryParams,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
          });
        }
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState(
          {
            NACH_failpull: json,
          }
          // function () {
          //   const $ = window.$;
          //   var table = $("#findash").DataTable();

          // }
        );
      })
      .catch((error) => console.log("error", error));
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }

  handelChange = (checked) => {
    console.log(checked);
    this.setState({
      pull_flag: checked ? 1 : 0,
    });
  };
  chkall = (e) => {
    const $ = window.$;
    if (e.target.checked) {
      $(".sub_chk").prop("checked", true);
    } else {
      $(".sub_chk").prop("checked", false);
    }
    var selected = [];
    $(".sub_chk:checkbox:checked").each(function(index, data) {
      selected.push(data.value);
    });
    this.setState({
      Pull_check: selected,
    });
  };
  handleCheckboxChange = (event) => {
    let newArray = [...this.state.Pull_check, event.target.value];
    if (this.state.Pull_check.includes(event.target.value)) {
      newArray = newArray.filter((day) => day !== event.target.value);
    }
    this.setState({
      Pull_check: newArray,
    });
  };
  add_bouncecharge = (e) => {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    for (let index = 0; index < this.state.Pull_check.length; index++) {
      console.log(this.state.Pull_check[index]);
      if (uid) {
        const formdata = new FormData();
        formdata.append("userid", uid);
        formdata.append("nach_process_id", this.state.Pull_check[index]);
        customFetch(process.env.REACT_APP_URL + "/set/loan/charges", {
          method: "POST",
          headers: { "api-token": api_token },
          body: formdata,
        })
          .then((res) => {
            return res.json();
          })
          .then((json) => {
            if (
              json.api_code === 4 ||
              json.api_code === 3 ||
              json.api_code === 5
            ) {
              localStorage.clear();
              this.props.history.push("/");
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        this.setState({
          isErrOpen: true,
          api_error_message: "Please Contact Support",
        });
      }
    }
    window.location.reload(false);
  };
  remove_bouncecharge = (e) => {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    for (let index = 0; index < this.state.Pull_check.length; index++) {
      console.log(this.state.Pull_check[index]);
      if (uid) {
        const formdata = new FormData();
        formdata.append("userid", uid);
        formdata.append("nach_process_id", this.state.Pull_check[index]);
        customFetch(process.env.REACT_APP_URL + "/remove/loan/charges", {
          method: "POST",
          headers: { "api-token": api_token },
          body: formdata,
        })
          .then((res) => {
            return res.json();
          })
          .then((json) => {
            if (
              json.api_code === 4 ||
              json.api_code === 3 ||
              json.api_code === 5
            ) {
              localStorage.clear();
              this.props.history.push("/");
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        this.setState({
          isErrOpen: true,
          api_error_message: "Please Contact Support",
        });
      }
    }
    window.location.reload(false);
  };
  updatemonthdata = (e) => {
    this.setState(
      { monthdata: e.target.value },
      console.log(this.state.monthdata) /*this.fetchfailpull()*/
    );
  };
  render() {
    const {
      api_error_message,
      isErrOpen,
      isUpdate,
      NACH_failpull,
      Mandate_fail,
      Pull_check,
    } = this.state;
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    console.log("Pull_check", this.state.Pull_check);
    console.log("monthdata", this.state.monthdata);

    return (
      <>
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>

        <section className="main-sec">
          <div className="container fix-width">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="cso_icon_img"
                  alt=""
                />
              </div>
              <div className="head-title">NACH / Mandate Fail Case</div>
              <div className="log-out-report">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>
            <Tabs
              activeTab="1"
              className=""
              ulClassName="tabs-data"
              activityClassName="bg-primary"
              onClick={(event, tab) =>
                tab === 2 ? this.fetchfailmandate() : ""
              }
            >
              <Tab title="Pull" className="mr-3">
                <div className="mt-3">
                  <div className="loan-charge-holder">
                    <div className="row">
                      <div className="col-4">
                        {/* <select
                          className="form-control"
                          name="monthdata"
                          onChange={(e)=>this.setState({ monthdata: e.target.value })}
                        >
                          <option>select Month</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </select> */}
                        <input
                          type="date"
                          id="date_select"
                          className="form-control"
                          defaultValue={moment(this.state.from_date).format(
                            "YYYY-MM-DD"
                          )}
                          value={this.state.from_date}
                          min={`${
                            new Date(moment("2022-11-15").format("YYYY-MM-DD"))
                              .toISOString()
                              .split(timezone.Time)[0]
                          }`}
                          onChange={(e) =>
                            this.setState({ from_date: e.target.value })
                          }
                          name="from_date"
                        />
                      </div>
                      <div className="col-4">
                        <input
                          type="date"
                          id="date_select"
                          className="form-control"
                          defaultValue={moment(this.state.to_date).format(
                            "YYYY-MM-DD"
                          )}
                          value={this.state.to_date}
                          min={`${
                            this.state.from_date
                              ? new Date(this.state.from_date)
                                  .toISOString()
                                  .split(timezone.Time)[0]
                              : new Date().toISOString().split(timezone.Time)[0]
                          }`}
                          onChange={(e) =>
                            this.setState({ to_date: e.target.value })
                          }
                          name="to_date"
                        />
                      </div>
                      <button
                        className="btn btn-primary"
                        onClick={() => this.fetchfailpull()}
                      >
                        Search
                      </button>
                    </div>
                    <div className="text-right">
                      <div className="row">
                        <button
                          className="btn btn-primary m-1"
                          disabled={Pull_check.length === 0}
                          onClick={this.add_bouncecharge}
                        >
                          Add Bounce Charge
                        </button>
                        <button
                          className="btn btn-primary m-1"
                          disabled={Pull_check.length === 0}
                          onClick={this.remove_bouncecharge}
                        >
                          Remove Bounce Charge
                        </button>
                      </div>
                    </div>
                  </div>
                  {NACH_failpull.length > 0
                    ? NACH_failpull.map((pulldata, index) => (
                        <div className="card-data" key={index}>
                          <div className="head-data">
                            <div className="row container">
                              <div className="col-1">
                                <input
                                  type="checkbox"
                                  id={`pull_${index}`}
                                  value={pulldata.table_id}
                                  className="sub_chk"
                                  onChange={this.handleCheckboxChange}
                                />
                              </div>
                              <div className="col-6">
                                <div>Loan ID:</div>
                                <div className="head-lid">
                                  {pulldata.loan_id}
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="pulamt">
                                  <span>
                                    {pulldata.amount
                                      ? pulldata.amount + " /-"
                                      : "NA"}
                                  </span>
                                </div>
                                <div className="pullamt-label text-center mt-2">
                                  <span>Next Pull Amount</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="hhdetails">
                            <h2 className="border-bottom mt-3 pb-1">
                              {pulldata.partner_name}
                            </h2>
                            <div class="bank-detail">
                              <div class="bankdetail-data adatcol">
                                Added AT
                                <span class="float-right">
                                  {pulldata.added_at}
                                </span>
                              </div>
                              <div class="bankdetail-data tb-border apratcol">
                                Approvel AT
                                <span class="float-right">
                                  {pulldata.approved_at}
                                </span>
                              </div>
                              <div class="bankdetail-data">
                                Payment Vendor
                                <span class="float-right">
                                  {pulldata.payment_vendor_name}
                                </span>
                              </div>
                            </div>
                            <h2 className="mt-3 pb-1">Reason</h2>
                            <div className="fail_reson">
                              {pulldata.rejection_reason}
                            </div>
                            <div className="fail_nachsts mt-2">
                              {pulldata.settlement_status === settlement_type.Fail ? (
                                <span>No Bounce Charge Applied</span>
                              ) : pulldata.settlement_status === settlement_type.Bounce ? (
                                <span>Bounce Charge Applied</span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    : ""}
                  {/* <table className="table table-hover" id="findash">
                    <thead>
                      <tr>
                        <th scope="col">
                          <input
                            type="checkbox"
                            id="chkall"
                            onChange={this.chkall}
                          />
                        </th>
                        
                        <th scope="col">Loan Id</th>
                        
                        <th scope="col">Next Pull Amount</th>
                        <th scope="col">Added At</th>
                        <th scope="col">Approved At</th>
                        <th scope="col">Payment Vendor</th>
                        <th scope="col">Partner Name</th>
                        <th scope="col">Reason</th>
                        <th scope="col">NACH Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {NACH_failpull.length > 0 ? (
                        NACH_failpull.map((pulldata, index) => (
                          <tr className="nach-pull" key={index}>
                            <td>
                              <input
                                type="checkbox"
                                id={`pull_${index}`}
                                value={pulldata.table_id}
                                className="sub_chk"
                                onChange={this.handleCheckboxChange}
                              />
                            </td>
                            
                            <td>{pulldata.loan_id}</td>
                            
                            <td>{pulldata.amount}</td>
                            <td>{pulldata.added_at}</td>
                            <td>{pulldata.approved_at}</td>
                            <td>{pulldata.payment_vendor_name}</td>
                            <td>{pulldata.partner_name}</td>
                            <td>{pulldata.rejection_reason}</td>
                            <td>
                              {pulldata.settlement_status === "F" ? (
                                <div className="labelbg ubg">
                                  No Bounce Charge Applied
                                </div>
                              ) : pulldata.settlement_status === "B" ? (
                                <div className="labelbg rbg">
                                  Bounce Charge Applied
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colspan="9">
                            <div className="text-center">No Data Found</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th scope="col">#</th>
                        
                        <th scope="col">Loan Id</th>
                        
                        <th scope="col">Next Pull Amount</th>
                        <th scope="col">Added At</th>
                        <th scope="col">Approved At</th>
                        <th scope="col">Payment Vendor</th>
                        <th scope="col">Partner Name</th>
                        <th scope="col">Reason</th>
                        <th scope="col">NACH Status</th>
                      </tr>
                    </tfoot>
                  </table> */}
                </div>
              </Tab>
              <Tab title="Mandate" className="mr-3">
                <div className="mt-3">
                  <div className="loan-charge-holder">
                    <div className="row">
                      <div className="col-4">
                        <input
                          type="date"
                          id="date_select"
                          className="form-control"
                          defaultValue={moment(this.state.from_date_m).format(
                            "YYYY-MM-DD"
                          )}
                          value={this.state.from_date_m}
                          min={`${
                            new Date(moment("2022-11-15").format("YYYY-MM-DD"))
                              .toISOString()
                              .split(timezone.Time)[0]
                          }`}
                          onChange={(e) =>
                            this.setState({ from_date_m: e.target.value })
                          }
                          name="from_date_m"
                        />
                      </div>
                      <div className="col-4">
                        <input
                          type="date"
                          id="date_select"
                          className="form-control"
                          defaultValue={moment(this.state.to_date).format(
                            "YYYY-MM-DD"
                          )}
                          value={this.state.to_date_m}
                          min={`${
                            this.state.from_date_m
                              ? new Date(this.state.from_date_m)
                                  .toISOString()
                                  .split(timezone.Time)[0]
                              : new Date().toISOString().split(timezone.Time)[0]
                          }`}
                          onChange={(e) =>
                            this.setState({ to_date_m: e.target.value })
                          }
                          name="to_date_m"
                        />
                      </div>
                      <button
                        className="btn btn-primary"
                        onClick={() => this.fetchfailmandate()}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  {Mandate_fail.length > 0
                    ? Mandate_fail.map((pulldata, index) => (
                        <div className="card-data" key={index}>
                          <div className="head-data">
                            <div className="row container">
                              <div className="col-6">
                                <div>Loan ID:</div>
                                <div className="head-lid">
                                  {pulldata.loan_id}
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="pulamt">
                                  <span>
                                    {pulldata.next_pull_amount
                                      ? pulldata.next_pull_amount + " /-"
                                      : "NA"}
                                  </span>
                                </div>
                                <div className="pullamt-label text-center mt-2">
                                  <span>Next Pull Amount</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="hhdetails">
                            <div className="row border-bottom mt-3 pb-1">
                              <div className="col-8">
                                <h2>{pulldata.partner_name}</h2>
                              </div>
                              <div className="col-4">
                                <div className="pulamt">
                                  <span>
                                    {pulldata.nach_status_type_description
                                      ? pulldata.nach_status_type_description
                                      : "NA"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="bank-detail">
                              <div class="bankdetail-data adatcol">
                                Added AT
                                <span class="float-right">
                                  {pulldata.added_at}
                                </span>
                              </div>
                              <div class="bankdetail-data tb-border apratcol">
                                Approvel AT
                                <span class="float-right">
                                  {pulldata.approved_at}
                                </span>
                              </div>
                              <div class="bankdetail-data">
                                Payment Vendor
                                <span class="float-right">
                                  {pulldata.payment_vendor_name}
                                </span>
                              </div>
                            </div>
                            <h2 className="mt-3 pb-1">Reason</h2>
                            <div className="fail_reson">
                              {pulldata.rejection_reason}
                            </div>
                          </div>
                        </div>
                      ))
                    : ""}
                  {/* <table className="table table-hover" id="findash_advance">
                    <thead>
                      <tr>
                        <th scope="col">Loan Id</th>

                        <th scope="col">Next Pull Amount</th>
                        <th scope="col">Added At</th>
                        <th scope="col">Approved At</th>
                        <th scope="col">Payment Vendor</th>
                        <th scope="col">Partner Name</th>
                        <th scope="col">Reason</th>
                        <th scope="col">NACH Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Mandate_fail.length > 0 ? (
                        Mandate_fail.map((pulldata, index) => (
                          <tr className="nach-pull" key={index}>
                            <td>{pulldata.loan_id}</td>

                            <td>{pulldata.next_pull_amount}</td>
                            <td>{pulldata.added_at}</td>
                            <td>{pulldata.approved_at}</td>
                            <td>{pulldata.payment_vendor_name}</td>
                            <td>{pulldata.partner_name}</td>
                            <td>{pulldata.rejection_reason}</td>
                            <td>{pulldata.nach_status_type_description}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colspan="9">
                            <div className="text-center">No Data Found</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th scope="col">Loan Id</th>

                        <th scope="col">Next Pull Amount</th>
                        <th scope="col">Added At</th>
                        <th scope="col">Approved At</th>
                        <th scope="col">Payment Vendor</th>
                        <th scope="col">Partner Name</th>
                        <th scope="col">Reason</th>
                        <th scope="col">NACH Status</th>
                      </tr>
                    </tfoot>
                  </table> */}
                </div>
              </Tab>
            </Tabs>
          </div>
        </section>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}

export default withRouter(NACH_Fail_Cases);
