import React, { Component } from "react";
import { withRouter } from "react-router";
import { logout } from "./Utility";

import ChatSupportBtn from "./ChatSupportBtn";
import customFetch from "./apicall/api";

class PartnerLOSDownloads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner_id: "",
      isErrOpen: false,
      api_error_message: "",
    };
  }
  componentDidMount() {
    document.title = "Partner LOS Download";
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    // const formdata = new FormData();
    // formdata.append("userid", uid);
    // customFetch(process.env.REACT_APP_URL + "/getrole", {
    //   method: "POST",
    //   headers: { "api-token": api_token },
    //   body: formdata,
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     if (data.api_code === 4) {
    //       localStorage.clear();
    //       this.props.history.push('/');
    //     }
    //     console.log(data.max_unit_level_id);

    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  downloadApplicantCoApplicantList(partner_id) {
    var filename = "";

    if (partner_id == 6) {
      filename = "UGRO";
    }

    const uid = localStorage.getItem("in_userid");
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    formdata.append("partner_id", partner_id);
    formdata.append("bc_id", "B");
    customFetch(process.env.REACT_APP_URL + "/create/excel/loan/list/partner", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        const contentType = res.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return res.json().then((data) => {
            this.setState({
              isErrOpen: true,
              api_error_message: data.message,
            });
          });
        } else {
          res.blob().then((img) => {
            const url = window.URL.createObjectURL(img);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename + "_Applicant_List.xlsx");
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });

          const formdata1 = new FormData();
          formdata1.append("partner_id", partner_id);
          formdata1.append("bc_id", "C");
          customFetch(
            process.env.REACT_APP_URL + "/create/excel/loan/list/partner",
            {
              method: "POST",
              headers: { "api-token": api_token },
              body: formdata1,
            }
          )
            .then((res) => {
              res.blob().then((img) => {
                const url = window.URL.createObjectURL(img);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  filename + "_Co_Applicant_List.xlsx"
                );
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
              });
            })
            .catch((error) => console.log("error", error));
        }
      })
      .catch((error) => console.log("error", error));
  }
  // logout(){
  //   localStorage.clear();
  //   this.props.history.push('/');
  // }
  render() {
    // console.log("Local storage uid",uid)
    const { isErrOpen, api_error_message } = this.state;
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    return (
      <>
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>

        <section className="main-sec">
          <div className="container fix-width">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="cso_icon_img"
                  alt=""
                />
              </div>
              <div className="head-title">LOS Download</div>
              <div className="log-out-report">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>

            <div className="dashboard-card-holder">
              <div
                className="card card-border-small col-sm-6 py-2"
                onClick={() => this.downloadApplicantCoApplicantList(6)}
              >
                <div className="card-body">
                  <div className="image-holder-4">
                    <img
                      src={require("../assets/images/check-list.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>UGRO - LOS Download</h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}

export default withRouter(PartnerLOSDownloads);
