import React, { Component } from "react";
import Papa from "papaparse";
import customFetch from "./apicall/api";
import { DatePicker, Select } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;
class Utr extends Component {
  constructor(props) {
    super(props);
    this.InputFile = React.createRef();
    this.state = {
      rows: [],
      isStatus: false,
      isLoading: false,
      isUploaded: false,
      isDisabled: false,
      nachpartner: [],
      mapped_branch: [],
      partnerpayment: [],
      isExport: false,
      utrdata: [],
    };
  }

  componentDidMount() {
    this.getnachpartner();
    this.getmappedbranch();
    this.getUTRData();
  }
  getUTRData() {
    const api_token = localStorage.getItem("api_token");
    //this.state.utrdata = [];
    const params = {
      userid: localStorage.getItem("in_userid"),
    };
    if (this.state.fromdate && this.state.todate) {
      params["from_date"] = this.state.fromdate;
      params["to_date"] = this.state.todate;
    }
    if (this.state.utr_status) {
      params["utr_status"] = this.state.utr_status;
    }
    if (this.state.branch_name) {
      const stringFormatbranch = this.state.branch_name.join(",");
      params["branch_name"] = stringFormatbranch;
    }
    if (this.state.partner) {
      const stringFormatpartner = this.state.partner.join(",");
      params["partner"] = stringFormatpartner;
    }

    const queryParams = new URLSearchParams(params).toString();
    customFetch(process.env.REACT_APP_URL + "/utr?" + queryParams, {
      method: "GET",
      headers: {
        "api-token": api_token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (this.state.isExport) {
          this.setState(
            {
              utrdata_bkp: json.data,
            },
            () => this.updatecount()
          );
        } else {
          this.setState(
            {
              utrdata: json.data,
              utrdata_bkp: json.data,
            },
            () => this.updatecount()
          );
        }
      })
      .catch((error) => console.log("error", error));
  }

  updatecount() {
    let count = 0;
    this.state.utrdata.forEach((item) => {
      if (item.utr_status === "Not Uploaded") {
        count++;
      }
    });
    this.setState({
      total_case: this.state.utrdata.length,
      utr_notuploaded: count,
      utr_uploaded: this.state.utrdata.length - count,
    });
    if (this.state.isExport) {
      this.exportToCSV();
    }
  }
  exportToCSV() {
    // Define column headers
    const headers = [
      "CFC Name",
      "Loan ID",
      "Client Name",
      "Partner Name",
      "Partner Reference No. 1",
      "Partner Reference No. 2",
      "Amount Disbursed",
      "Disbursement Date",
      "Account Number",
      "IFSC Number",
      "UTR Number",
      "UTR Status",
      "UTR Upload Timestamp",
      "Uploader By",
    ];

    let data = this.state.utrdata_bkp;
    // Convert data to CSV format
    const csv = [
      headers.join(","),
      ...data.map((item) =>
        Object.values(item)
          .map((value) => (value === null ? "" : value))
          .join(",")
      ),
    ].join("\n");

    // Create a Blob object containing the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a link element to trigger the download
    const link = document.createElement("a");
    if (link.download !== undefined) {
      let filename = "UTR_" + moment().format("YYYYMMDDHHmmss");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename + ".csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log(
        "Your browser doesn't support automatic download. Please right-click the link below and choose 'Save link as' to download the CSV file."
      );
      console.log(csv);
    }
    this.setState({ isExport: false });
  }
  getmappedbranch() {
    const api_token = localStorage.getItem("api_token");
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    customFetch(
      process.env.REACT_APP_URL +
        "/user/mapped/details?userid=" +
        localStorage.getItem("in_userid"),
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => {
        return res.json();
      })

      .then((json) => {
        // if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
        //   localStorage.clear();
        //   this.props.history.push("/");
        // }
        this.setState({
          mapped_branch: json.data1,
        });
      })
      .catch((error) => console.log("error", error));
  }
  getnachpartner() {
    const api_token = localStorage.getItem("api_token");
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    customFetch(process.env.REACT_APP_URL + "/get/nach/partner/list", {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          nachpartner: json,
        });
      })
      .catch((error) => console.log("error", error));
  }
  handleBrowse = () => {
    this.InputFile.current.click();
  };

  handleFileChange = (event) => {
    if (!event.target.files[0]) return;

    Papa.parse(event.target.files[0], {
      header: true,
      complete: (result) => {
        let data = result.data.filter((obj) => {
          return (
            obj["loan_id"] != null &&
            obj["utr"] != null &&
            obj["ifsc"] != null &&
            obj["account_number"] != null &&
            obj["amount"] != null &&
            obj["remittance_date"] != null
          );
        });
        this.setState({ rows: data, isStatus: false });
      },
    });
    event.target.value = null;
    this.setState({ isDisabled: false });
  };

  handleUpload = async () => {
    if (this.state.rows.length == 0) return;
    const response = await this.uploadAPI();
    if (response) {
      this.processUploadResponse(response);
      this.setState({ isDisabled: true });
      alert("File Uploaded Successfully");
    }
  };

  uploadAPI = async () => {
    try {
      const api_token = localStorage.getItem("api_token");
      const _headers = new Headers();
      const body = {
        userid: localStorage.getItem("in_userid"),
        data: this.state.rows,
      };
      this.setState({ isDisabled: true, isLoading: true });
      const response = await customFetch(
        process.env.REACT_APP_URL + "/utr/upload",
        {
          method: "POST",
          headers: {
            "api-token": api_token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      // console.log(response.status)

      if (response.status != 200) {
        alert("Error while uploading file, Upload correct format");
        this.setState({ isDisabled: false });
        throw "Internal Server Error";
      }
      let data = await response.json();
      // console.log(data)
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  processUploadResponse = (response) => {
    const failedRows = response["failed_rows"];
    const uploadedRows = response["uploaded_rows"];
    const updatedRows = this.state.rows.map((row) => {
      const failedRow = failedRows.find(
        (failedRow) => failedRow.utr === row.utr
      );
      if (failedRow) {
        return {
          ...row,
          error: failedRow.error,
        };
      }
      const uploadedRow = uploadedRows.find(
        (uploadedRow) => uploadedRow.utr === row.utr
      );
      if (uploadedRow) {
        return {
          ...row,
          error: "",
        };
      }
      return row;
    });
    // console.log(updatedRows)
    this.setState({
      rows: updatedRows,
      isStatus: true,
    });
  };

  handleDownloadFormat = () => {
    const json = [
      {
        loan_id: "",
        account_number: "",
        ifsc: "",
        utr: "",
        remittance_date: "YYYY-MM-DD",
        amount: "",
      },
    ];
    const csv_text = Papa.unparse(json, { header: true });
    // console.log(csv_text)
    const element = document.createElement("a");
    const file = new Blob([csv_text], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "UTR Data.csv";
    document.body.appendChild(element);
    element.click();
  };
  getnachpartner() {
    const api_token = localStorage.getItem("api_token");
    customFetch(process.env.REACT_APP_URL + "/get/partner/payment/list", {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
          });
        }
        return res.json();
      })

      .then((json) => {
        this.setState({
          partnerpayment: json,
        });
        this.state.isSpin = false;
        this.forceUpdate();
      })
      .catch((error) => console.log("error", error));
    /* Fetch Partner List End */
  }
  exportdata() {
    const startDate = moment(this.state.fromdate);
    const endDate = moment(this.state.todate);
    console.log("enddate", startDate + "-" + endDate);
    console.log("partner", this.state.partner);
    console.log("branch_name", this.state.branch_name);
    console.log("utr_status", this.state.utr_status);
    let isvalid = false;
    if (this.state.fromdate && this.state.todate) {
      const monthDifference = endDate.diff(startDate, "months");
      if (monthDifference < 1) {
        isvalid = true;
        console.log("Selected date range is less than one month");
      } else if (monthDifference === 1) {
        isvalid = true;
        console.log("Selected date range is exactly one month");
      } else {
        alert("Please Select Disbursed Date For One Month");
      }
    } else {
      alert("Please select Disbursed Date");
    }
    if (isvalid) {
      this.setState(
        {
          isExport: true,
        },
        () => this.getUTRData()
      );
    }
  }
  render() {
    console.log("mapped_branch", this.state.mapped_branch);
    return (
      <div className="container">
        <div className="utr_navbar">
          <button
            className="button back_button"
            onClick={() => this.props.history.goBack()}
          >
            <img src={require("../assets/images/back.png")} />
          </button>
          <h3>UTR</h3>
          <button className="button" onClick={this.logout}>
            <img src={require("../assets/images/power-off.png")} alt="" />
          </button>
        </div>
        <div style={{ borderBottom: "2px solid" }} className="utr_main_block">
          <div className="utr_action_block">
            <h4 className="utr_action_block_header">
              <b>Upload file :</b>{" "}
              <button
                onClick={this.handleDownloadFormat}
                className="btn btn-sm btn-secondary"
              >
                Download Format
              </button>
            </h4>
            <div className="utr_action_button_block">
              <input
                hidden
                type="file"
                ref={this.InputFile}
                onChange={this.handleFileChange}
                accept=".csv"
              />
              <button
                className="btn btn-md btn-primary"
                onClick={this.handleBrowse}
              >
                Browse File
              </button>
              <button
                className="btn btn-md btn-primary"
                disabled={this.state.isDisabled}
                onClick={this.handleUpload}
              >
                {this.state.isLoading ? (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                ) : (
                  ""
                )}{" "}
                Upload
              </button>
            </div>
            <div className="utr_rows_count">
              {this.state.rows.length != 0
                ? this.state.rows.length + " records"
                : ""}
            </div>
          </div>

          <div className="utr_table_block">
            <table className="utr_data_table">
              <thead>
                <tr>
                  <th>Loan ID</th>
                  <th>Beneficiary AC no</th>
                  <th>IFSC code</th>
                  <th>UTR No</th>
                  <th>Disbursement Date</th>
                  <th>Amount</th>
                  {this.state.isStatus ? <th>Status</th> : null}
                </tr>
              </thead>
              <tbody>
                {this.state.rows.map((row) => {
                  return (
                    <tr key={row["utr"]}>
                      <td>{row["loan_id"]}</td>
                      <td>{row["account_number"]}</td>
                      <td>{row["ifsc"]}</td>
                      <td>{row["utr"]}</td>
                      <td>{row["remittance_date"]}</td>
                      <td>{row["amount"]}</td>
                      {this.state.isStatus ? (
                        <td
                          className={`${
                            row["error"] == ""
                              ? "utr_upload_success"
                              : "utr_upload_error"
                          }`}
                        >
                          {row["error"] == ""
                            ? "Uploaded successfully!"
                            : row["error"]}
                        </td>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <div style={{ borderTop: "2px solid" }} className="utr_main_block">
          <h3>View / Export Data</h3>
          <div className="form-row">
            <div className="col-md-3">
              <label>Disbursed Date</label>
              <RangePicker
                format={"YYYY-MM-DD"}
                pickerClass="form-control"
                placeholder={["Start Date", "End Date"]}
                onChange={(dates, e) => {
                  if (dates && dates.length === 2) {
                    const startDate = moment(dates[0])
                      .startOf("month")
                      .format("YYYY-MM-DD");
                    const endDate = moment(dates[1])
                      .endOf("month")
                      .format("YYYY-MM-DD");

                    this.setState({ fromdate: startDate, todate: endDate });
                  }
                }}
                disabledDate={(current) =>
                  (current &&
                    current <
                      moment()
                        .subtract(6, "months")
                        .startOf("day")) ||
                  current > moment()
                }
              />
            </div>
            <div className="col-md-3">
              <label>Branch Name</label>
              <Select
                showSearch
                mode="multiple"
                placeholder="Select Branch"
                onChange={(e) => this.setState({ branch_name: e })}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option && option.label
                    ? option.label.toLowerCase()
                    : ""
                  ).includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA && optionA.label
                    ? optionA.label.toLowerCase()
                    : ""
                  ).localeCompare(
                    optionB && optionB.label ? optionB.label.toLowerCase() : ""
                  )
                }
                className="form-control"
                options={[
                  {
                    label: "Select an action", // You can customize this label
                    value: "", // An empty value
                  },
                  ...(this.state.mapped_branch &&
                    this.state.mapped_branch
                      .map((action) => {
                        if (action.action_event != "onsave") {
                          return {
                            label: action.branch_name,
                            value: action.branch_id,
                          };
                        } else {
                          return null;
                        }
                      })
                      .filter((item) => item !== null)),
                ]}
                allowClear={true}
              />
            </div>
            <div className="col-md-3">
              <label>Partner</label>
              <Select
                showSearch
                mode="multiple"
                placeholder="Select Partner"
                onChange={(e) => this.setState({ partner: e })}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option && option.label
                    ? option.label.toLowerCase()
                    : ""
                  ).includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA && optionA.label
                    ? optionA.label.toLowerCase()
                    : ""
                  ).localeCompare(
                    optionB && optionB.label ? optionB.label.toLowerCase() : ""
                  )
                }
                className="form-control"
                options={[
                  {
                    label: "Select an action", // You can customize this label
                    value: "", // An empty value
                  },
                  ...(this.state.partnerpayment &&
                    this.state.partnerpayment
                      .map((action) => {
                        if (action.action_event != "onsave") {
                          return {
                            label: action.partner_name,
                            value: action.partner_id,
                          };
                        } else {
                          return null;
                        }
                      })
                      .filter((item) => item !== null)),
                ]}
                allowClear={true}
              />
            </div>
            <div className="col-md-3">
              <label>UTR Status</label>
              <Select
                showSearch
                placeholder="Select Status"
                className="form-control"
                onChange={(e) => this.setState({ utr_status: e })}
                options={[
                  {
                    label: "Select an action", // You can customize this label
                    value: "", // An empty value
                  },
                  {
                    label: "SUCCESS", // You can customize this label
                    value: "SUCCESS", // An empty value
                  },
                  {
                    label: "FAILURE", // You can customize this label
                    value: "FAILURE", // An empty value
                  },
                  {
                    label: "NOT UPLOADED", // You can customize this label
                    value: "NOT UPLOADED", // An empty value
                  },
                ]}
              />
            </div>
            <div className="col-md-3 mt-2">
              <button
                className="btn btn-primary"
                onClick={() => this.getUTRData()}
              >
                Search
              </button>
            </div>
          </div>

          <div className="tot_amt_block mt-4">
            <div class="amt_b">
              <div class="blue_block_sh block_sh">
                <span class="countLabel">
                  <b>{this.state.total_case}</b>
                </span>
              </div>
              <span class="bluetext">Total Cases Disbursed</span>
            </div>
            <div class="amt_b">
              <div class="red_block_sh block_sh">
                <span class="countLabel">
                  <b>{this.state.utr_notuploaded}</b>
                </span>
              </div>
              <span class="redtext">Total Cases UTR Not Uploaded</span>
            </div>
            <div class="amt_b">
              <div class="green_block_sh block_sh">
                <span class="countLabel">
                  <b>{this.state.utr_uploaded}</b>
                </span>
              </div>
              <span class="greentext">Total Cases UTR Uploaded</span>
            </div>
          </div>
          <div className="utr_table_block">
            <button
              className="btn btn-primary float-right mb-1"
              onClick={() => this.exportdata()}
            >
              Export
            </button>
            <table className="utr_data_table">
              <thead>
                <tr>
                  <th>CFC Name</th>
                  <th>Loan ID</th>
                  <th>Client Name</th>
                  <th>Partner Name</th>
                  <th>Partner Reference No. 1</th>
                  <th>Partner Reference No. 2</th>
                  <th>Amount Disbursed</th>
                  <th>Disbursement Date</th>
                  <th>Account Number</th>
                  <th>IFSC Number</th>
                  <th>UTR Number</th>
                  <th>UTR Status</th>
                  <th>UTR Upload Timestamp</th>
                  <th>Uploader By</th>
                </tr>
              </thead>
              <tbody>
                {this.state.utrdata &&
                  this.state.utrdata.map((row) => {
                    return (
                      <tr key={row["utr"]}>
                        <td>{row["CFC"]}</td>
                        <td>{row["Loan_Id"]}</td>
                        <td>{row["Client_name"]}</td>
                        <td>{row["partner_name"]}</td>
                        <td>{row["partner_ref_no1"]}</td>
                        <td>{row["partner_reference_number_two"]}</td>
                        <td>{row["Amount"]}</td>
                        <td>{row["Disburse_Date"]}</td>
                        <td>{row["Beneficiary_Account_Number"]}</td>
                        <td>{row["IFSC_Code"]}</td>
                        <td>{row["UTR"]}</td>
                        <td>{row["utr_status"]}</td>
                        <td>{row["UTR_Upload_at"]}</td>
                        <td>{row["UTR_upload_by"]}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Utr;
