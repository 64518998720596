import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import style from "../assets/css/CashDepositeBank.css";
import { FilterContext } from "../context/FilterToDepositSlip";
import ChatSupportBtn from "./ChatSupportBtn";
import { logout } from "./Utility";
import moment, { isDate } from "moment";
import { DatePicker } from "antd";
import customFetch from "./apicall/api";
const { RangePicker } = DatePicker;
class To_deposit_slip extends Component {
  static contextType = FilterContext;

  constructor(props) {
    super(props);
    this.state = {
      viewImg: "",
      reversed: [],
      reversed_bkp: [],
      current_balance: "",
      selectedFile: [],
      isOpen: false,
      list: [],
      sort_data: [],
      search_text: "",
      temp_array: [],
      sort_htl: "",
      counter: 1,
      isMonthFlag: true,
      isStatusFlag: false,
      branchFilter: false,
      startEndDate: "",
      submitStatusFlag: 0,
      approveStatusFlag: 0,
      rejectStatusFlag: 0,
      isNameFlag: false,
      uniqueNames: [],
      uniqueBranch: [],
      branchFilterFlag: 0,
      employeeFilterFlag: 0,
      monthValue: "",
      statusValue: "",
      nameValue: "",
      branchValue: "",
    };
    this.ImgchangeHandler = this.ImgchangeHandler.bind(this);
    this.searchCustomer = this.searchCustomer.bind(this);
    this.handleSearchText = this.handleSearchText.bind(this);
    this.handlesorthtl = this.handlesorthtl.bind(this);
    this.handlesort = this.handlesort.bind(this);
    this.handleFilterMenu = this.handleFilterMenu.bind(this);
    this.addName = this.addName.bind(this);
    this.addbranchName = this.addbranchName.bind(this);
    this.addEmployeeName = this.addEmployeeName.bind(this);
    this.filter = this.filter.bind(this);
    this.setDate = this.setDate.bind(this);
  }

  filter(event) {
    //console.log(this.state.temp_array, " test ", this.context.filters);
    this.state.sort_data = [];
    console.log("temp_array", this.state.temp_array);
    // if (this.state.temp_array.length === 0) {
    //   // this.state.temp_array = ["submit"];
    //   // this.state.submitStatusFlag = 1;
    //   let temp = [];
    //   let temp1 = [];
    //   temp = this.state.reversed_bkp;
    //   for (let i = 0; i < temp.length; i++) {
    //     if (temp[i].status == 2) {
    //       this.state.sort_data.push(temp[i]);
    //     }
    //   }
    //   temp1 = this.state.sort_data;
    //   console.log("temp zero", temp1);
    //   this.state.reversed = temp1.reverse();
    // }

    if (this.state.temp_array.length > 0) {
      console.log("temp not zero");
      let temp = [];

      if (!isNaN(Date.parse(this.state.temp_array[0]))) {
        for (let i = 0; i < this.state.reversed_bkp.length; i++) {
          if (
            moment(this.state.reversed_bkp[i].submitted_at).isBetween(
              this.state.temp_array[0],
              this.state.temp_array[1]
            )
          ) {
            temp.push(this.state.reversed_bkp[i]);
          }
        }
      } else {
        temp = this.state.reversed_bkp;
      }

      console.log("temp", temp);
      this.state.sort_data = [];

      if (
        this.state.submitStatusFlag == 1 ||
        this.state.approveStatusFlag == 1 ||
        this.state.rejectStatusFlag == 1
      ) {
        for (let i = 0; i < temp.length; i++) {
          if (this.state.approveStatusFlag == 1 && temp[i].status == 3) {
            this.state.sort_data.push(temp[i]);
          }
          if (this.state.submitStatusFlag == 1 && temp[i].status == 2) {
            this.state.sort_data.push(temp[i]);
          }
          if (this.state.rejectStatusFlag == 1 && temp[i].status == 4) {
            this.state.sort_data.push(temp[i]);
          }
        }
        temp = this.state.sort_data;
        this.state.sort_data = [];
      }

      let flag = 0;
      this.state.uniqueNames.forEach((item) => {
        if (item[1] == 1) {
          flag = 1;
        }
      });

      if (flag) {
        for (let i = 0; i < temp.length; i++) {
          for (let j = 0; j < this.state.temp_array.length; j++) {
            if (this.state.temp_array[j].indexOf(temp[i].employee_name) >= 0) {
              this.state.sort_data.push(temp[i]);
            }
          }
        }
        temp = this.state.sort_data;
        this.state.sort_data = [];
      }
      flag = 0;
      this.state.uniqueBranch.forEach((item) => {
        if (item[1] == 1) {
          flag = 1;
        }
      });

      if (flag) {
        for (let i = 0; i < temp.length; i++) {
          for (let j = 0; j < this.state.temp_array.length; j++) {
            if (
              this.state.temp_array[j].indexOf(
                temp[i].branch_details[0].branch_name
              ) >= 0
            ) {
              this.state.sort_data.push(temp[i]);
            }
          }
        }
        temp = this.state.sort_data;
        this.state.sort_data = [];
      }

      // this.state.reversed = this.state.sort_data.reverse();
      this.state.reversed = temp.reverse();
    } else {
      let temp = [];
      let temp1 = [];
      temp = this.state.reversed_bkp;
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].status == 2) {
          this.state.sort_data.push(temp[i]);
        }
      }
      temp1 = this.state.sort_data;
      console.log("temp zero", temp1);
      this.state.reversed = temp1.reverse();
      //this.state.reversed = this.state.reversed_bkp;
    }

    //console.log("Output => ", this.state.reversed);
    // this.state.temp_array = [];
    this.forceUpdate();
  }

  addEmployeeName(event) {
    if (event.target.checked) {
      this.state.temp_array.push(event.target.id);
      this.state.employeeFilterFlag = 1;
      if (this.state.nameValue != "") {
        this.state.nameValue += ", " + event.target.id;
      } else {
        this.state.nameValue += event.target.id;
      }

      for (let i = 0; i < this.state.uniqueNames.length; i++) {
        if (event.target.id == this.state.uniqueNames[i][0]) {
          this.state.uniqueNames[i][1] = 1;
        }
      }
    } else {
      let tmp = [];
      if (this.state.nameValue != "") {
        tmp = this.state.nameValue.split(", ");
      }
      for (let i = 0; i < this.state.temp_array.length; i++) {
        if (event.target.id == this.state.temp_array[i]) {
          this.state.temp_array.splice(i, 1);
        }
      }
      let flag = 0;

      for (let i = 0; i < this.state.temp_array.length; i++) {
        this.state.uniqueNames.forEach((item) => {
          if (this.state.temp_array[i] == item) {
            flag = 1;
          }
        });
      }

      if (flag) {
        this.state.employeeFilterFlag = 1;
      } else {
        this.state.employeeFilterFlag = 0;
      }
      for (let i = 0; i < tmp.length; i++) {
        if (event.target.id == tmp[i]) {
          tmp.splice(i, 1);
        }
      }

      this.state.nameValue = tmp.join(", ");

      for (let i = 0; i < this.state.uniqueNames.length; i++) {
        if (event.target.id == this.state.uniqueNames[i][0]) {
          this.state.uniqueNames[i][1] = 0;
        }
      }
    }
    //console.log(this.state.employeeFilterFlag);
    this.forceUpdate();
    //console.log(this.state.temp_array);
  }

  addbranchName(event) {
    if (event.target.checked) {
      this.state.temp_array.push(event.target.id);
      this.state.branchFilterFlag = 1;
      if (this.state.branchValue != "") {
        this.state.branchValue += ", " + event.target.id;
      } else {
        this.state.branchValue += event.target.id;
      }

      for (let i = 0; i < this.state.uniqueBranch.length; i++) {
        if (event.target.id == this.state.uniqueBranch[i][0]) {
          this.state.uniqueBranch[i][1] = 1;
        }
      }
    } else {
      let tmp = [];
      if (this.state.branchValue != "") {
        tmp = this.state.branchValue.split(", ");
      }
      for (let i = 0; i < this.state.temp_array.length; i++) {
        if (event.target.id == this.state.temp_array[i]) {
          this.state.temp_array.splice(i, 1);
        }
      }
      let flag = 0;

      for (let i = 0; i < this.state.temp_array.length; i++) {
        this.state.uniqueBranch.forEach((item) => {
          if (this.state.temp_array[i] == item) {
            flag = 1;
          }
        });
      }

      if (flag) {
        this.state.branchFilterFlag = 1;
      } else {
        this.state.branchFilterFlag = 0;
      }
      for (let i = 0; i < tmp.length; i++) {
        if (event.target.id == tmp[i]) {
          tmp.splice(i, 1);
        }
      }

      this.state.branchValue = tmp.join(", ");

      for (let i = 0; i < this.state.uniqueBranch.length; i++) {
        if (event.target.id == this.state.uniqueBranch[i][0]) {
          this.state.uniqueBranch[i][1] = 0;
        }
      }
    }
    // console.log(this.state.branchFilterFlag);
    this.forceUpdate();
    //console.log(this.state.temp_array);
  }
  addName(event) {
    if (event.target.checked) {
      this.state.temp_array.push(event.target.id);

      if (this.state.statusValue != "") {
        this.state.statusValue += ", " + event.target.id;
      } else {
        this.state.statusValue += event.target.id;
      }

      if (event.target.id === "submit") {
        this.state.submitStatusFlag = 1;
      } else if (event.target.id === "rejected") {
        this.state.rejectStatusFlag = 1;
      } else if (event.target.id === "approve") {
        this.state.approveStatusFlag = 1;
      }
      // this.forceUpdate();
    } else {
      let tmp = [];
      if (this.state.statusValue != "") {
        tmp = this.state.statusValue.split(", ");
      }
      for (let i = 0; i < this.state.temp_array.length; i++) {
        if (event.target.id == this.state.temp_array[i]) {
          this.state.temp_array.splice(i, 1);

          if (event.target.id === "submit") {
            this.state.submitStatusFlag = 0;
          } else if (event.target.id === "rejected") {
            this.state.rejectStatusFlag = 0;
          } else if (event.target.id === "approve") {
            this.state.approveStatusFlag = 0;
          }
        }
      }

      for (let i = 0; i < tmp.length; i++) {
        if (event.target.id == tmp[i]) {
          tmp.splice(i, 1);
        }
      }

      this.state.statusValue = tmp.join(", ");
    }
    this.forceUpdate();

    //console.log(this.state.temp_array);
  }

  handleFilterMenu(event) {
    if (event.currentTarget.id == "monthFilter") {
      this.state.counter = 1;
      this.state.isMonthFlag = true;
      this.state.isStatusFlag = false;
      this.state.isNameFlag = false;
      this.state.branchFilter = false;
    } else if (event.currentTarget.id == "statusFilter") {
      // console.log(this.state.temp_array);
      this.state.temp_array.forEach((item) => {
        if (item === "submit") {
          this.state.submitStatusFlag = 1;
        } else if (item === "rejected") {
          this.state.rejectStatusFlag = 1;
        } else if (item === "approve") {
          this.state.approveStatusFlag = 1;
        }
      });
      this.state.counter = 2;
      this.state.isMonthFlag = false;
      this.state.isStatusFlag = true;
      this.state.isNameFlag = false;
      this.state.branchFilter = false;
    } else if (event.currentTarget.id == "nameFilter") {
      this.state.counter = 3;
      this.state.isMonthFlag = false;
      this.state.isStatusFlag = false;
      this.state.isNameFlag = true;
      this.state.branchFilter = false;
    } else if (event.currentTarget.id == "branchFilter") {
      this.state.counter = 4;
      this.state.isMonthFlag = false;
      this.state.isStatusFlag = false;
      this.state.isNameFlag = false;
      this.state.branchFilter = true;
      //console.log(this.state.uniqueBranch);
    }
    // console.log(this.state.submitStatusFlag);
    this.forceUpdate();
  }

  handlesort(event) {
    let temp = this.state.reversed;
    this.state.sort_data = [];
    this.context.setSortHTL(this.state.sort_htl);

    if (this.state.sort_htl == "high") {
      this.state.sort_data = []
        .concat(this.state.reversed)
        .sort((a, b) => b.amt_submitted - a.amt_submitted);

      this.state.reversed = this.state.sort_data;
    } else if (this.state.sort_htl == "low") {
      this.state.sort_data = []
        .concat(this.state.reversed)
        .sort((a, b) => a.amt_submitted - b.amt_submitted);

      this.state.reversed = this.state.sort_data;
    } else {
      this.state.reversed = temp;
      temp = [];
    }

    this.forceUpdate();
  }

  handlesorthtl(event) {
    // this.state.sort_htl = event.target.value;

    if (event.target.checked) {
      this.state.sort_htl = event.target.value;
    }

    console.log(this.state.sort_htl);
    this.forceUpdate();
  }
  searchCustomer(event) {
    // event.preventDefault();

    this.state.sort_data = [];

    this.state.cnames = this.state.reversed;
    this.context.setSearchText(this.state.search_text);
    if (this.state.search_text !== "") {
      for (let i = 0; i < this.state.cnames.length; i++) {
        //console.log(this.state.cnames[i].employee_name);
        if (!isNaN(this.state.search_text)) {
          if (
            parseInt(this.state.cnames[i].cash_balance_id) ===
            parseInt(this.state.search_text)
          ) {
            this.state.sort_data.push(this.state.cnames[i]);
          }
        } else {
          if (
            this.state.cnames[i].employee_name
              .toLowerCase()
              .indexOf(this.state.search_text.toLowerCase()) >= 0
          ) {
            this.state.sort_data.push(this.state.cnames[i]);
            // console.log();
          }
          if (
            this.state.cnames[i].cluster_branch_name
              .toLowerCase()
              .indexOf(this.state.search_text.toLowerCase()) >= 0
          ) {
            this.state.sort_data.push(this.state.cnames[i]);
            // console.log();
          }
          //var surname = "zzz";
          let filteredArray = this.state.cnames.filter((element) =>
            element.branch_details.some(
              (subElement) =>
                subElement.branch_name.toLowerCase() ===
                this.state.search_text.toLowerCase()
            )
          );
          if (filteredArray.length > 0) {
            this.state.sort_data.push(this.state.cnames[i]);
          }
        }
        this.state.reversed = [...new Set(this.state.sort_data)];
      }
    } else {
      this.state.reversed = this.state.reversed_bkp;
    }

    this.forceUpdate();
  }
  handleSearchText(event) {
    this.state.search_text = event.target.value;

    if (this.state.search_text == "") {
      this.state.reversed = this.state.reversed_bkp;
      this.forceUpdate();
    }
  }
  ImgchangeHandler(event, bid) {
    event.preventDefault();

    console.log("Balanceid", bid);
    var file = event.target.files[0];
    //console.log(file);
    this.setState({
      selectedFile: file,
    });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    formdata.append("file", file);
    formdata.append(
      "blob_url",
      "deposit_slips_by_transaction_officer/" + file.name
    );
    formdata.append("id", bid);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
      },
      body: formdata,
    };
    customFetch(process.env.REACT_APP_URL + "/save/files/bucket", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.fetchDetails();
      }).catch((error) => console.log("error", error));;
  }
  componentDidMount() {
    document.title = "Deposit Money";

    const { filters, setFilters, filterConfig } = this.context;
    console.warn("Filter123",filterConfig);

    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    this.fetchDetails();
    this.filter();
  }
  fetchDetails = () => {
    const uid = localStorage.getItem("in_userid");
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    // const formdata = new FormData();
    // formdata.append("in_userid", uid);
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": api_token,
      },
      //body: formdata,
    };
    customFetch(
      process.env.REACT_APP_URL + "/get/list/denominations/finops",
      requestOptions
    )
      .then((response) => {
        console.log(response.headers);
        console.log(response.headers.entries());
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        if (data.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState(
          {
            reversed: data,
            reversed_bkp: data.reverse(),
            // temp_array: this.context.filters,
            // counter: this.context.filterConfig,
            search_text: this.context.searchText,
            sort_htl: this.context.sort_htl,
          },
          () => {
            this.filter();
            if (this.context.searchText != "") this.searchCustomer();
            this.handlesort();
            this.setState({ temp_array: [] });
          }
        );
      })
      .catch((error) => console.log("An error occured ", error));
  };
  delimage(id) {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    formdata.append("id", id);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
      },
      body: formdata,
    };
    customFetch(
      process.env.REACT_APP_URL + "/delete/filename/cashbalid",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.fetchDetails();
      }).catch((error) => console.log("error", error));;
  }
  viewimg(path) {
    console.log(path.split("/")[0]);
    console.log(path.split("/")[1]);
    console.log(path.split("/")[2]);
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    // formdata.append("bucket_name", path.split("/")[0]);
    // formdata.append("blob_name", path.split("/")[4]);
    formdata.append("blob_url", path);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
      },
      body: formdata,
    };
    customFetch(
      process.env.REACT_APP_URL + "/download/files/bucket",
      requestOptions
    ).then((res) => {
      if (res.api_code === 4) {
        localStorage.clear();
        this.props.history.push("/");
      }
      res.blob().then((img) => {
        //console.log(URL.createObjectURL(img))
        this.setState({
          viewImg: URL.createObjectURL(img),
          isOpen: true,
        });
      }).catch((error) => console.log("error", error));;
    });
    // .then((response) => {
    //   return response.body;
    // })
    // .then((data) => {
    //   //const href = URL.createObjectURL(data);
    //   console.log("Download:",data);
    //   this.setState({
    //     viewImg:data
    //   })
    // });
  }
  totalbalance() {
    const { reversed } = this.state;
    if (reversed.length > 0) {
      const total = reversed.reduce(
        (prevValue, currentValue) =>
          parseFloat(prevValue) + parseFloat(currentValue.amt_submitted),
        0
      );
      return total.toLocaleString("en-IN");
    } else {
      const total = 0;
      return total;
    }
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }
  // Below Function To Set Start Date And End Date for filter
  setDate = (dateString) => {
    this.state.startEndDate = dateString;
    // console.log(moment(this.state.startEndDate[0]));
    // console.log(moment(this.state.startEndDate[1]));

    if (dateString) {
      let temp = this.state.temp_array;
      this.state.temp_array = [];

      this.state.temp_array.push(
        moment(dateString[0])
          .set("hour", "00")
          .set("minute", "00")
          .format("ddd, D MMM YYYY H:mm:ss")
      );

      this.state.temp_array.push(
        moment(dateString[1])
          .set("hour", "23")
          .set("minute", "59")
          .format("ddd, D MMM YYYY H:mm:ss")
      );
      this.state.monthValue = moment(dateString[0])
        .set("hour", "00")
        .set("minute", "00")
        .format("ddd, D MMM YYYY H:mm:ss");

      this.state.monthValue +=
        " - " +
        moment(dateString[1])
          .set("hour", "00")
          .set("minute", "00")
          .format("ddd, D MMM YYYY H:mm:ss");
      temp.forEach((item) => {
        this.state.temp_array.push(item);
      });
    } else {
      // console.log(isNaN(Date.parse(this.state.temp_array[0])));
      if (this.state.temp_array.length == 0) {
        this.setState({ temp_array: [] });
      } else {
        if (!isNaN(Date.parse(this.state.temp_array[0]))) {
          this.state.temp_array = this.state.temp_array.slice(2);
        }
      }

      this.state.monthValue = "";
    }
    this.forceUpdate();
  };
  render() {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const { reversed, current_balance, viewImg, isOpen } = this.state;
    //console.log("Rev:", reversed);
    //     const courses = ["BETMA"];
    //     const r = reversed.filter(d => d.branch_details.every(c => courses.includes(c.branch_name)));
    // console.log("Filter_Data:",r);
    //console.log("Date:", moment().format("MMMM Do YYYY, H:mm:ss"));
    if (this.state.reversed_bkp.length > 0) {
      let _branchlist = [];
      this.state.reversed_bkp.map((item, index) => {
        var findItem = this.state.uniqueNames.find(
          (x) => x.indexOf(item.employee_name) == 0
        );
        if (!findItem) this.state.uniqueNames.push([item.employee_name, 0]);

        var findItem1 = this.state.uniqueBranch.find(
          (x) => x.indexOf(item.branch_details[0].branch_name) == 0
        );
        if (!findItem1)
          this.state.uniqueBranch.push([item.branch_details[0].branch_name, 0]);

        // var findbranch = this.state.uniqueBranch.find(
        //   (z) => z.indexOf(item.branch_details[0].branch_name) == 0
        // );
        // if (!findbranch)
        //   this.state.uniqueBranch.push(item.branch_details.branch_name);
        _branchlist.push(item.branch_details[0].branch_name);
      });

      this.state.uniqueNames = this.state.uniqueNames.sort((a, b) =>
        a[0].localeCompare(b[0])
      );

      this.state.uniqueBranch = this.state.uniqueBranch.sort((a, b) =>
        a[0].localeCompare(b[0])
      );

      // this.state.uniqueBranch = [...new Set(this.state.uniqueBranch)];

      // this.state.uniqueBranch = _branchlist.sort((a, b) =>
      //   a[0].localeCompare(b[0])
      // );
      // let tmpp = [...new Set(_branchlist)];

      // tmpp = [...new Set(_branchlist)];

      // tmpp.forEach((item) => {
      //   this.state.uniqueBranch.push([item,0]);
      // });
      // this.forceUpdate();
    }

    return (
      <>
        <div
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="filter-box"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="filter_window_1">
                <div className="filter_header">
                  <table className="table filter-table">
                    <thead>
                      <tr>
                        <th>Filter</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        onClick={this.handleFilterMenu}
                        id="monthFilter"
                        className="unique-name-click"
                      >
                        <td>
                          {this.state.counter == 1 ? <b>{"Month"}</b> : "Month"}
                          <br />
                          <span className="to_filter_values">
                            {this.state.monthValue}
                          </span>
                        </td>
                      </tr>
                      <tr
                        onClick={this.handleFilterMenu}
                        id="statusFilter"
                        className="unique-name-click"
                      >
                        <td>
                          {this.state.counter == 2 ? (
                            <b>{"Status"}</b>
                          ) : (
                            "Status"
                          )}
                          <br />
                          <span className="to_filter_values">
                            {this.state.statusValue}
                          </span>
                        </td>
                      </tr>
                      <tr
                        onClick={this.handleFilterMenu}
                        id="nameFilter"
                        className="unique-name-click"
                      >
                        <td>
                          {this.state.counter == 3 ? <b>{"Name"}</b> : "Name"}
                          <br />
                          <span className="to_filter_values">
                            {this.state.nameValue}
                          </span>
                        </td>
                      </tr>
                      <tr
                        onClick={this.handleFilterMenu}
                        id="branchFilter"
                        className="unique-name-click"
                      >
                        <td>
                          {this.state.counter == 4 ? (
                            <b>{"Branch"}</b>
                          ) : (
                            "Branch"
                          )}
                          <br />
                          <span className="to_filter_values">
                            {this.state.branchValue}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="filter_amt" id="filter-sub-menu">
                  {this.state.isMonthFlag && (
                    <div className="unique-names">
                      {/* Add Date Range Filter */}
                      <div className="filter-date">
                        <RangePicker
                          onChange={this.setDate}
                          defaultValue={this.state.startEndDate}
                        />
                      </div>
                    </div>
                  )}
                  {this.state.isStatusFlag && (
                    <div className="unique-names">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="submit"
                                name="submit"
                                onClick={this.addName}
                                checked={
                                  this.state.submitStatusFlag ? true : false
                                }
                              />
                              <label htmlFor="vehicle1"> &nbsp; Submit</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="approve"
                                name="approve"
                                onClick={this.addName}
                                checked={
                                  this.state.approveStatusFlag ? true : false
                                }
                              />
                              <label htmlFor="vehicle1"> &nbsp; Approve</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="rejected"
                                name="rejected"
                                onClick={this.addName}
                                checked={
                                  this.state.rejectStatusFlag ? true : false
                                }
                              />
                              <label htmlFor="vehicle1"> &nbsp; Rejected</label>
                            </td>
                          </tr>
                          {/* <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="verify"
                                name="verify"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1"> &nbsp; Verify</label>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {this.state.isNameFlag && (
                    <div className="unique-names">
                      <table className="table table-borderless">
                        {this.state.uniqueNames.map((item, key) => (
                          <tbody>
                            <tr>
                              <td>
                                {" "}
                                <input
                                  type="checkbox"
                                  id={item[0]}
                                  name={item[0]}
                                  onChange={this.addEmployeeName}
                                  checked={item[1] ? true : false}
                                />
                                <label htmlFor="vehicle1">
                                  {" "}
                                  &nbsp; {item[0]}
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  )}
                  {this.state.branchFilter && (
                    <div className="unique-names">
                      <table className="table table-borderless">
                        {this.state.uniqueBranch.map((item, key) => (
                          <tbody>
                            <tr>
                              <td>
                                {" "}
                                <input
                                  type="checkbox"
                                  id={item[0]}
                                  name={item[0]}
                                  onChange={this.addbranchName}
                                  checked={item[1] ? true : false}
                                />
                                <label htmlFor="vehicle1">
                                  {" "}
                                  &nbsp; {item[0]}
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  )}
                  <div className="filter-btn-div">
                    <button
                      data-dismiss="modal"
                      className="btn btn-primary btn-block btn-lg rbtn"
                      onClick={() => {
                        this.context.setFilters(
                          this.state.temp_array,
                          this.state.counter
                        );
                        this.filter();
                      }}
                    >
                      APPLY
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-sm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="sort-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content sort_block col-sm-12">
              <span className="bluetext-header-sort">SORT BY</span>

              <div className="sortBlueBox">
                <div>
                  <input
                    type="radio"
                    id="htl"
                    name="htl"
                    value="high"
                    onChange={this.handlesorthtl}
                  />
                  <label htmlFor="high">&nbsp;High To Low</label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="htl"
                    name="htl"
                    onChange={this.handlesorthtl}
                    value="low"
                  />
                  <label htmlFor="low">&nbsp;Low To High</label>
                </div>
              </div>
              <button
                data-dismiss="modal"
                type="button"
                className="btn btn-primary btn-lg btn-block rbtn search_elements"
                onClick={this.handlesort}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
        {/* Search POPUP */}
        <div
          className="modal fade bd-example-modal-sm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="search"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content search_block col-sm-12">
              {/* <form onSubmit={this.searchCustomer}> */}

              <input
                type="text"
                className="form-control rinput1 search_elements1"
                id="sfname"
                name="sfname"
                placeholder="Enter First Name or Branch or Cash Balance ID"
                required
                onChange={this.handleSearchText}
              />
              <button
                data-dismiss="modal"
                onClick={this.searchCustomer}
                type="submit"
                className="btn btn-primary btn-lg btn-block rbtn search_elements"
              >
                Search
              </button>
              {/* </form> */}
            </div>
          </div>
        </div>
        {/* Search POPUP End */}
        {/* Image popup Start */}
        <div
          className={
            isOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <img src={viewImg} />
            </div>
          </div>
        </div>
        {/* Image popup End */}

        <div className="container">
          <div className="log-out">
            <img
              src={require("../assets/images/power-off.png")}
              onClick={() => logout()}
              alt=""
            />
          </div>
          <div className="ca-header-box">
            <div className="collection_block_header">
              <div className="title-holder_bg">
                <div
                  className="back_icon_bg"
                  onClick={() => this.props.history.goBack()}
                >
                  <img
                    src={require("../assets/images/back.png")}
                    className="back_icon_img"
                  />
                </div>
                <h2>TO Deposit Slip</h2>
                <div className="back_icon_bg">
                  <img
                    src={require("../assets/images/search.png")}
                    alt="#"
                    className="back_icon_img"
                    data-toggle="modal"
                    data-target="#search"
                    // onClick={() => {
                    //   this.props.history.push({
                    //     pathname: "/Deposit_money",
                    //     state: { api_token: api_token },
                    //   });
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className="filter-btn">
              <a
                data-toggle="modal"
                data-target="#sort-box"
                data-backdrop="static"
              >
                <div className="filter-options-container justify-content-between">
                  <img
                    src={require("../assets/images/list.png")}
                    alt="#"
                    className="list_img"
                  />
                </div>
              </a>
              <a
                data-toggle="modal"
                data-target="#filter-box"
                data-backdrop="static"
              >
                <div className="filter-options-container justify-content-between">
                  <img
                    src={require("../assets/images/sort.png")}
                    alt="#"
                    className="list_img"
                  />
                </div>
              </a>
            </div>
          </div>
          {/* <div className="card mt-4 card-border">
            <div className="card-body">
              <div className="card-label">Cash To be Reconciled</div>
              <div className="card-amtlabel">
                Rs. {parseFloat(current_balance).toLocaleString("en-IN")}
              </div>
            </div>
          </div> */}
          <div className="card mt-4 card-border">
            <div className="card-body">
              <div className="card-label">Total Cash Balance To Approve</div>
              <div className="card-amtlabel">Rs. {this.totalbalance()}</div>
            </div>
          </div>
          {reversed.length > 0
            ? reversed.map((item) => (
                <div
                  className="card mt-4 card-border"
                  key={item.cash_balance_id}
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/To_slip_verification",
                      state: {
                        api_token: api_token,
                        cash_balance_id: item.cash_balance_id,
                        path: item.filename,
                        amt_submitted: item.amt_submitted,
                        to_id: item.userid,
                        slipstatus: item.status,
                      },
                    })
                  }
                >
                  <div className="card-body">
                    <div className="csoname-holder">
                      {item.employee_name.toLowerCase()}(
                      {item.branch_details[0].branch_name})
                      <span className="cash-id">
                        <b>Cash Balanceid:</b> {item.cash_balance_id}
                      </span>
                    </div>
                    {/* {item.branch_details.map((branch)=>(
                      <>{branch.branch_name}</>
                    )
                    )} */}
                    <div>
                      {/* <input type="button" onClick={()=> window.open("https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/LetterA.svg/1200px-LetterA.svg.png", "_blank")} /> */}
                      <div className="card-label">Cash Deposit</div>
                      <div className="card-amtdep">
                        Rs.{" "}
                        {parseFloat(item.amt_submitted).toLocaleString("en-IN")}
                      </div>
                    </div>
                    {/* {item.filename ? (
                      <div className="fileupload-holder">
                        <div className="file-upload">
                          <div
                            className="view-img"
                            onClick={() => this.viewimg(item.filename)}
                          >
                            <img
                              src={require("../assets/images/view_blue.png")}
                              alt=""
                            />
                            <span>View</span>
                          </div>
                          <div
                            className="remove-img"
                            onClick={() => {
                              if (window.confirm("are you sure to delete ?"))
                                this.delimage(item.cash_balance_id);
                            }}
                          >
                            <img
                              src={require("../assets/images/delete.png")}
                              alt=""
                            />
                            <span>Delete</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""                      
                    )} */}
                    <div className="card-foot-area">
                      <div className="date-time-area">
                        <div className="date-area">
                          <span>Date : </span>
                          <span>
                            {item.submitted_at.split(" ")[0] +
                              " " +
                              item.submitted_at.split(" ")[1] +
                              " " +
                              item.submitted_at.split(" ")[2] +
                              " " +
                              item.submitted_at.split(" ")[3]}
                          </span>
                        </div>
                        <div className="time-area">
                          <span>Time : {item.submitted_at.split(" ")[4]}</span>
                        </div>
                      </div>
                      <div className="status-area">
                        <span>
                          {item.status === 1
                            ? "Saved"
                            : item.status === 2
                            ? "Submitted"
                            : item.status === 3
                            ? "Fin-ops Approved"
                            : item.status === 4
                            ? "Rejected"
                            : ""}
                        </span>
                        <span className="cbfoot-img-holder-submit">i</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : ""}
          {/* <div className="card mt-4 card-border">
            <div className="card-body">
              <div className="csoname-holder">Vikas Singh (Ref. No.)</div>
              <div>
                <div className="card-label">Cash Deposit</div>
                <div className="card-amtdep">Rs. 10,000</div>
              </div>
              <div className="fileupload-holder">
                <div className="file-upload">
                  <div className="view-img">
                    <img
                      src={require("../assets/images/view_blue.png")}
                      alt=""
                    />
                    <span>View</span>
                  </div>
                  <div className="remove-img">
                    <img src={require("../assets/images/delete.png")} alt="" />
                    <span>Delete</span>
                  </div>
                </div>
              </div>
              <div className="card-foot-area">
                <div className="date-time-area">
                  <div className="date-area">
                    <span>Date : </span>
                    <span>Thu, 28 Jul 2022</span>
                  </div>
                  <div className="time-area">
                    <span>Time : 15:11:22</span>
                  </div>
                </div>
                <div className="status-area">
                  <span>Submitted</span>
                  <span className="cbfoot-img-holder-submit">i</span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}
export default withRouter(To_deposit_slip);
